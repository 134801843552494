import types from "@/data/types"

const ticketGenerateHelper = (type) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_APP:
        case types.OSS_WEB:
            return {
                general: true,
                barcode: true,
                onlyMobile: true,
                additional: true,
                cloakify: true,
                addLetter: true,
            }
        case types.SG:
            return {
                general: true,
                barcode: true,
                onlyMobile: true,
                additional: false,
                cloakify: true,
                addLetter: true,
            }
        case types.BALLPARK_APP:
            return {
                general: true,
                barcode: true,
                onlyMobile: false,
                additional: false,
                cloakify: true,
                addLetter: false,
            }
        default:
            return {
                general: false,
                barcode: false,
                onlyMobile: false,
                additional: false,
                cloakify: false,
                addLetter: false,
            }
    }
}

export default ticketGenerateHelper