export const loadingMixin = {
    data: () => ({
        response: {
            loading: false,
            code: null,
            timestamp: null,
            variant: null,
            message: null,
        },
        history: {
            loading: false,
            code: null,
            timestamp: null,
            variant: null,
            message: null,
        }
    }),
    methods: {
        loadingTest(callback, loading = true) {
            if (process.env.NODE_ENV === 'development') {
                console.error("loadingTest is called, do not forget to remove called function before deploy")
                this.response.loading = loading
                setTimeout(() => {
                    this.response.loading = false
                    if (callback !== undefined) {
                        callback()
                    }
                }, loading ? 1200: 0)
            }
        },
        delay(callback, timeout = 500) {
            setTimeout(() => {
                if (callback !== undefined) {
                    callback()
                }
            }, timeout)
        },
    },
    watch: {
        'response.loading': {
            immediate: false,
            handler(to) {
                if (to) {
                    this.response.message = null
                }
            }
        }
    },
}
