const TYPE = {
    OSS_API: "oss-api",
    OSS_APP: "oss-app",
    OSS_WEB: "oss-web",
    XLS_TO_QR: "xls-to-qr",
    PK_PASS: "pkPassToPDF",
    TM_PHONE: "tmPhonePasses",
    TM_INVOICES: "tmInvoices",
    OSS_API_INVENTORY: "oss-api-inventory",
    OSS_APP_INVENTORY: "oss-app-inventory",
    OSS_WEB_INVENTORY: "oss-web-inventory",
    TM_INVENTORY: "tmInventory",
    OSS_RESET: "ossReset",
    OSS_CREDITS: "oss-credits",
    SG: "seatgeek",
    XLS_TO_QR_SG: "sg-to-qr",
    BALLPARK_APP: "mlb-app-ballpark",
    BALLPARK_WEB: "mlb-web-ballpark",
    BALLPARK_LINK: "mlb-ballpark-link",
    BALLPARK_CREATE: "mlb-ballpark-create",
    BALLPARK_VERIFY: "verify-email-in-ballpark",
    TCOM_QR: "ticketscom",
}

export default TYPE
