import ossNavbarItems from "@/data/navbar"
import ossMenuItems from "@/data/menu"
import router from "@/router"

const routeMixin = {
    data: () => ({
        active: '/',
        isNavbar: false,
        isSidebar: false,
    }),
    methods: {
        onTitleClick() {
            if (this.$route.path !== '/') {
                router.push('/')
            }
        },
    },
    watch: {
        active: {
            immediate: true,
            handler(to) {
                this.isNavbar = ossNavbarItems.filter(item => item.id === to).length
                this.isSidebar = ossMenuItems.filter(item => item.id === to).length
                this.$emit('onChange', to)
            },
        },
        $route: {
            immediate: true,
            handler(to) {
                if (to.params?.page !== this.active) {
                    // TODO: Check v4 user & admin panel
                    this.active = to.path
                }
            }
        }
    },
}

export default routeMixin