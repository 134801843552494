<template>
  <v-container class="fill-height" v-bind:class="computedDivClass" fluid v-bind="$attrs">
    <v-row align="center" justify="center">
      <v-flex v-bind:class="computedClass">
        <slot></slot>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import centerComponentsProps from "@/base/data/props/center"
import componentMixin from "@/base/data/mixins/componentMixin"

export default {
  name: "CenterContent",
  props: centerComponentsProps,
  mixins: [componentMixin],
}
</script>
