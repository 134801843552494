<template>
  <CenterContent size="xs12">
    <SP :class="['mb-2', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
    <v-card class="pa-2" outlined>
      <TextBox
        :value="accounts"
        :label="viewHelpers.textBoxLabel"
        :hint="viewHelpers.textBoxHint"
        :error="textBoxError"
        :disabled="alert.loading"
        @input="onAccountChange" />
      <Alert outlined type="error" border="left" :message="warningError" />
      <Alert outlined :type="response.variant" border="left" :message="response.message" />
      <Alert :loading="alert.loading" :type="alert.variant" :message="alert.message" />
      <v-row>
        <v-col cols="6">
          <Button
            title="Verify Account"
            :loading="alert.loading"
            :class-object="['px-10']"
            @onClick="onButtonClick"
            prepend-icon="bx bx-refresh"
            outlined />
        </v-col>
        <v-col cols="6">
          <Button
            title="Show My History"
            :loading="alert.loading"
            :class-object="['px-10', 'float-right']"
            @onClick="onHistoryClick"
            prepend-icon="bx bx-history"
            outlined />
        </v-col>
      </v-row>
    </v-card>
    <BottomSheet
        v-model="historySheet"
        class="mb-10"
        @onClose="onHistoryClose">
      <Alert type="error" :message="history.message" />
      <DataTable
        title="History"
        custom-loading-type="table-thead, table-tbody, table-tfoot"
        filterable
        group-by="status"
        :loading="history.loading"
        :custom-options="datatableOptions"
        :custom-header-values="historyHeaderValues"
        :datatable-items="historyItems">
      </DataTable>
    </BottomSheet>
  </CenterContent>
</template>

<script>
import CenterContent from "@/base/components/container/CenterContent"
import PageMixin from "@/base/data/mixins/pageMixin"
import helper from "@/utils/viewHelper"
import TextBox from "@/base/components/form/TextBox"
import {baseValidation} from "@/data/view/validate"
import Button from "@/base/components/form/Button"
import HistoryAction from "@/view/components/HistoryAction"
import Alert from "@/base/components/view/Alert"
import Sp from "@/base/components/container/SP"
import BallparkServices from "@/services/BallparkServices"
import BottomSheet from "@/base/components/container/BottomSheet.vue"
import DataTable from "@/base/components/container/DataTable.vue"
import RequestService from "@/services/RequestService"
import TooltipLabel from "@/base/components/container/TooltipLabel.vue"
import SP from "@/base/components/container/SP.vue"
import socketMixin from "@/data/mixins/socketMixin"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"

export default {
  name: "ballparkVerifyAccount",
  components: {SP, TooltipLabel, DataTable, BottomSheet, Sp, TextBox, CenterContent, Button, HistoryAction, Alert},
  mixins: [PageMixin, socketMixin, pageProjectMixin],
  data: () => ({
    accounts: "",

    // TextBox variables
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],
    rules: {
      separator: ":",
      length: 3,
      items: ["email", "password_8", "email"],
    },

    // Loading
    alert: {
      message: null,
      variant: "info",
      loading: false,
    },

    // History variables
    historySheet: false,
    historyItems: [],
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  }),
  methods: {
    onAccountChange(accounts) {
      this.accounts = accounts
    },

    // Button Clicks
    onButtonClick() {
      this.response.message = null
      this.warningError = null
      this.textBoxError = ""
      this.textBoxErrorLines = []

      let validateResponse = baseValidation(this.accounts, this.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
        return
      }

      let account = validateResponse.validLines[0]
      const [email, password, email_to_verified] = account.split(":")
      let data = {
        account_user: email,
        account_password: password,
        email_to_be_verified: email_to_verified,
        custom_data: {
          ...this.custom_data,
        },
      }

      this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
      this.response.loading = true
      this.alert.loading = true
      this.alert.message = "Please stand by"

      BallparkServices.verify(data).then(response => {
        this.response = response.networkResponse(true)
        if (response.data.data.request_status === "requested") {
          this.socketRequestId = response.data.data.request_id
          this.alert.message = "We got your request. Scraping tickets... \nYou can close this page and check it later from history. (" + response.data.data.request_id + ")"
        } else {
          this.warningError = "An error occurred. Please try again"
          this.alert.message = null
          this.alert.loading = false
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      })
    },
    onTestClick() {
      console.log("Test click")
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    decorateHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        requested: [item.request_data.account_user, item.request_data.email_to_be_verified].join("\n"),
        result: item.api_response_message,
        status: item.request_status,
        request_time: this.printableDate(item.created_at),

        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    onHistoryClose() {
      this.historySheet = false
      this.historyItems = []
    },
    onSocketData(data) {
      if (data.status === 'failed') {
        this.alert.message = null
        this.alert.loading = false
        this.response.variant = 'error'
        this.response.message = data.response_message
        if (data.response_errors?.length > 0) {
          let errorMessages = data.response_errors.map(err => `${err.account_user} -> ${err._error_message}`).join("<br />")
          this.response.message += "<br/>" + errorMessages
        }
      } else {
        RequestService.historyDetail(data.request_id).then(response => {
          if (response.data.data.request_status === "completed") {
            this.response.variant = 'success'
            this.response.message = response.data.data.api_response_message
            this.response.message += "<br/>" + response.data.data.decorated_response[0].VERIFIED_EMAILS.map(acc => `${acc.email} -> ${acc.status}`).join("<br/>")
          }
        }).catch(error => {
          if (error.networkError === 'function') {
            this.response = error.networkError?.(error)
          } else {
            this.response = this.returnCustomError(error.toString())
          }
        }).finally(() => {
          this.alert.message = null
          this.alert.loading = false
        })
      }
    }
  },
  beforeMount() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel
  }
}
</script>