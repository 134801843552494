export const sidebarProps = {
    platform: {
        type: Object,
        required: true,
        default: {},
    },
    menuItems: {
        type: Array,
        required: false,
        default: null,
    },
    autoExpand: {
        type: Boolean,
        required: false,
        default: true,
    },
}
