<template>
  <div v-bind:class="computedDivClass">
    <v-simple-table
      :dense="density"
      :fixed-header="fixedHeader"
      :height="getHeight">
      <thead>
        <tr>
          <th class="text-left" v-for="(header, index) in getHeader" :name="index">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :name="index">
          <td v-for="header in getProperty" :name="index">
            {{ item[header] }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import simpleDataTableProps from "@/base/data/props/simpleDataTableProps"
import ComponentMixin from "@/base/data/mixins/componentMixin"
import {getHeaderValues, toSnakeCase} from "@/base/helper/utils"

export default {
  name: "SimpleDataTable",
  props: simpleDataTableProps,
  mixins: [ComponentMixin],
  computed: {
    getHeader() {
      if (this.customHeader) {
        return this.customHeader
      }
      if (this.items != null && this.items[0] != null) {
        let item = this.items[0]
        return getHeaderValues(item, [], this.customHeader)
      }
      return []
    },
    getProperty() {
      return this.getHeader.map(item => toSnakeCase(item))
    },
    getHeight() {
      if (this.height != null) {
        return this.height + "px"
      } else {
        return
      }
    },
  },
}
</script>
