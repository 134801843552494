import baseProps from "@/base/data/props/base"

const formHeaderProps = {
    ...baseProps,
    title: {
        type: String,
        required: true,
        default: null,
    },
    description: {
        type: String,
        required: false,
        default: null,
    },
    icon: {
        type: String,
        required: false,
        default: null,
    }
}

export default formHeaderProps