export const headerMatches = {
    id: "ID",
    eventId: "Event ID",
    name: "Event Name",
    datetimeString: "Time",
    venue: "Venue",
    teamName: "Team Name",
    datetime: "Date",
    count: "Count",
    canTransfer: "Can Transfer?",
    tokenState: "State",
    // Ticket-list mock data
    teamID: "Team ID",
    account: "Account",
    // eventName: "Event Name",
    eventVenue: "Event Venue",
    eventDate: "Event Date",
    section: "Section",
    row: "Row",
    seat: "Seat",
    // Others
    _item: "Item",
}

export const headerWidthMatches = {
    id: "50px",
    team: "100px",
    teamId: "150px",
    event_id: "100px",
    section: "100px",
    row: "100px",
    seat: "100px",
    name: "250px",
    event: "200px",
    eventName: "200px",
    action: "225px",
    time_range: "150px",
    status: "120px",
    editor: '150px',
    requested: '120px',
    request_type: "295px",
    request_time: "120px",
    created_at: '182px',
    result: "200px",
    additional: "150px",
}

export const headerIgnoreValues = [
    'canTransfer',
    'tokenState',
    'password',
    'id',
    'timestamp',
]

export const headerAlignmentValues = {
    count: 'center',
    section: 'center',
    row: 'center',
    seat: 'center',
    data: 'center',
    status: 'center',
    created_at: 'center',
    _item: ' d-none',
    _search: ' d-none',
}

export const headerGroupByValues = [
    'event',
    'section',
    'row',
    'seat',
    'date',
    'time',
    'venue',
    'editor',
    'status',
    'request_type',
    'requested',
    'result',
    'account_user',
    'event_id',
    'additional',
    'team',
]