<template>
  <v-card class="white elevation-0 rounded-lg pb-10">
    <FormHeader :icon="icon" :title="title" :description="description" class="pa-10 text-center" />
    <div v-bind:class="computedClass">
      <v-form :ref="computedRefName" v-if="hasForm" v-model="valid">
        <slot>No slot content defined</slot>
      </v-form>
      <slot v-else>No slot content defined</slot>
    </div>
  </v-card>
</template>

<script>
import panelProps from "@/base/data/props/panel"
import componentMixin from "@/base/data/mixins/componentMixin"
import FormHeader from "@/base/components/container/FormHeader"

export default {
  name: "Panel",
  props: panelProps,
  components: {FormHeader},
  emits: ['onFormChange'],
  mixins: [componentMixin],
  data: () => ({
    valid: false,
  }),
  methods: {
    validate() {
      this.$refs[this.computedRefName].validate()
    },
    reset() {
      this.$refs[this.computedRefName].reset()
    }
  },
  watch: {
    valid: {
      immediate: false,
      handler(to) {
        this.$emit('onFormChange', { validated: to, form: !!this.form })
      },
    },
  },
}
</script>