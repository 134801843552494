<template>
  <SP v-if="viewHelpers.enabled === false">
    This feature is not available yet
  </SP>
  <CenterContent v-else size="xs12">
    <SP :class="['mb-2', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }} {{ getAppTeamList() }}</SP>
    <LoadingCard :loading="queryLoading">
      <v-card class="pa-1" elevation="0">
        <TextBox
          :value="accounts"
          :label="viewHelpers.textBoxLabel"
          :hint="viewHelpers.textBoxHint"
          :error="textBoxError"
          :disabled="alert.loading"
          @input="onAccountChange" />
        <v-row>
          <v-col cols="6">
            <DateRangePicker
              @onPicked="onPicker"
              @toDateReset="onToDateReset"
              :disabled="alert.loading"
              :from-date="fromDate"
              :to-date="toDate" />
          </v-col>
          <v-col cols="3">
            <Checkbox
              v-if="viewHelpers.hasTBD"
              v-model="hasTBD"
              @input="(value) => hasTBD = value"
              :disabled="alert.loading"
              title="Also TBD events" />
          </v-col>
          <v-col cols="3">
            <Checkbox
                v-if="viewHelpers.includeOption"
                v-model="includeBarcode"
                @input="(value) => includeBarcode = value"
                :disabled="alert.loading"
                title="Include barcodes" />
          </v-col>
        </v-row>
        <Alert loading :type="alert.variant" :message="alert.message" />
        <Alert outlined type="error" border="left" :message="warningError" />
        <Alert outlined type="error" border="left" :message="response.message" />
        <Alert type="success" :message="successMessage">
          <div class="text-right">
            <Button :class-div-object="['d-inline-block', 'mr-4']" title="Download" outlined small @onClick="downloadFile"></Button>
            <Button v-if="requestingCloakify" :class-div-object="['d-inline-block']" title="Cloakify" outlined small @onClick="downloadCloakify"></Button>
          </div>
        </Alert>
        <v-row class="mt-0">
          <v-col cols="4">
            <Button
              title="Scrape Account(s)"
              :loading="alert.loading"
              :class-object="['px-10']"
              @onClick="onButtonClick"
              prepend-icon="bx bx-refresh"
              outlined />
          </v-col>
          <v-col cols="4" class="text-center align-center align-content-center">
            <Button
                v-if="ticketItems != null && ticketItems.length > 0"
                outlined
                :class-object="['green--text']"
                @onClick="onCreatePO"
                title="Create PO" />
          </v-col>
          <v-col cols="4">
            <Button
              title="Show My History"
              :loading="alert.loading"
              :class-object="['px-10', 'float-right']"
              @onClick="onHistoryClick"
              prepend-icon="bx bx-history"
              outlined />
          </v-col>
        </v-row>
        <TicketList
          v-if="ticketItems != null"
          :items="ticketItems"
          :class-div-object="['my-5']">

          <template v-slot:footer="{ hasSelectedRow, selectedRows }">
            <v-row class="mt-2">
              <v-col cols="4">
                <Button
                    text outlined x-large
                    title="Generate QRs"
                    button-icon="bx bx-barcode"
                    @onClick="() => onGenerateClick(selectedRows)"
                    :class-div-object="['float-left']"
                    :disabled="!hasSelectedRow || forceDisabled"
                    :loading="alert.loading" />
              </v-col>
              <v-col cols="4" class="d-flex justify-center align-center">
                <Button
                    text outlined x-large
                    title="Print PDFs"
                    button-icon="bx bx-file"
                    @onClick="() => onPDFClick(selectedRows)"
                    :disabled="!hasSelectedRow"
                    :loading="alert.loading"
                    v-if="viewHelpers.hasPrint && disablePrint === false" />
                <span v-if="viewHelpers.hasPrint && disablePrint">
                  Print PDFs is only available for newly refreshed lists.
                </span>
              </v-col>
              <v-col cols="4">
                <Button
                    text outlined x-large
                    title="Download as Spreadsheet"
                    button-icon="bx bx-list-ol"
                    @onClick="() => onCSVClick(selectedRows)"
                    :class-div-object="['float-right']"
                    :disabled="!hasSelectedRow"
                    :loading="alert.loading" />
              </v-col>
            </v-row>
            <v-container fluid class="mt-5 ml-n2" v-if="viewHelpers.ticketGenerateHelper.general">
              <Checkbox v-if="viewHelpers.ticketGenerateHelper.barcode" :disabled="!hasSelectedRow  || forceDisabled" @input="(value) => { generateOptions.barcodeUnder = value }" :value="generateOptions.barcodeUnder" :class-object="['my-n4', 'pb-0']" title="Barcode under QR Image" color="primary" />
              <Checkbox v-if="viewHelpers.ticketGenerateHelper.onlyMobile" :disabled="!hasSelectedRow || forceDisabled" @input="(value) => { generateOptions.onlyMobile = value }" :value="generateOptions.onlyMobile" :class-object="['mt-2', 'pb-0']" title="'Only Mobile' note" color="primary" />
              <Checkbox v-if="viewHelpers.ticketGenerateHelper.additional" :disabled="!hasSelectedRow || forceDisabled" @input="(value) => { generateOptions.hasAdditional = value }" :value="generateOptions.hasAdditional" :class-object="['my-n2', 'pb-0']" title="Additional" color="primary" />
              <Checkbox v-if="viewHelpers.ticketGenerateHelper.cloakify" :disabled="!hasSelectedRow || forceDisabled" @input="(value) => { generateOptions.cloakify = value }" :value="generateOptions.cloakify" :class-object="['mt-n2', 'pb-0']" title="Cloakify" color="primary" />
              <v-row>
                <v-col cols="3">
                  <Input v-if="viewHelpers.ticketGenerateHelper.addLetter" :disabled="!hasSelectedRow || forceDisabled" label="Add letter" v-model="generateOptions.post_letter" />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </TicketList>
      </v-card>
    </LoadingCard>
    <BottomSheet
      v-model="historySheet"
      class="mb-10"
      @onClose="onHistoryClose">
      <Alert type="error" :message="history.message" />
      <DataTable
        title="History"
        custom-loading-type="table-thead, table-tbody, table-tfoot"
        filterable
        group-by="status"
        :loading="history.loading"
        :custom-options="datatableOptions"
        :custom-actions="customActions"
        :custom-header-values="historyHeaderValues"
        :datatable-items="historyItems">

        <template v-slot:item.account="{ item }">
          <TooltipLabel v-if="item.platform === 'seatgeek'" top :label="item._item.request_data.accounts?.map(account => account.account_performer + ':' + account.account_token).join('<br/>')">
            {{ item._item.request_data.accounts?.length }} accounts
          </TooltipLabel>
          <span v-else-if="item._item.request_data.accounts?.length <= 2" v-html="item.account"></span>
          <TooltipLabel v-else top :label="item._item.request_data.accounts?.map(account => account.account_team + ':' + account.account_user).join('<br/>')">
            {{ item._item.request_data.accounts?.length }} accounts
          </TooltipLabel>
        </template>

        <template v-slot:item.result="{ item }">
          <TooltipLabel v-if="item.result_tooltip && item.result_tooltip !== ''" top :label="item.result_tooltip">
            {{ item.result }}
          </TooltipLabel>
          <span v-else>{{ item.result }}</span>
        </template>

        <template v-slot:item.time_range="{ item }">
          <span v-html="item.time_range"></span>
        </template>

        <template v-slot:item.status="{ item }">
          <TooltipLabel top :label="item.request_id">
            <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
            <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
            <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
            <span v-else>{{ item.status }}</span>
          </TooltipLabel>
        </template>
      </DataTable>
    </BottomSheet>
  </CenterContent>
</template>

<script>
import PageMixin from "@/base/data/mixins/pageMixin"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"
import socketMixin from "@/data/mixins/socketMixin"
import CenterContent from "@/base/components/container/CenterContent"
import TextBox from "@/base/components/form/TextBox"
import Button from "@/base/components/form/Button"
import Alert from "@/base/components/view/Alert"
import HistoryAction from "@/view/components/HistoryAction"
import DateRangePicker from "@/base/components/form/DateRangePicker"
import ScrapeService from "@/services/ScrapeService"
import BottomSheet from "@/base/components/container/BottomSheet"
import DataTable from "@/base/components/container/DataTable"
import TicketList from "@/view/other/TicketList"
import LoadingModal from "@/base/components/container/LoadingModal"
import helper from "@/utils/viewHelper"
import {baseValidation, testAccounts} from "@/data/view/validate"
import {addDays, getDateWithPlusDays, getFormattedDate, getTodayFormattedDate} from "@/base/helper/date"
import Row from "@/base/components/container/Row"
import Checkbox from "@/base/components/form/Checkbox"
import TooltipLabel from "@/base/components/container/TooltipLabel"
import Description from "@/components/Description"
import RequestService from "@/services/RequestService"
import SP from "@/base/components/container/SP.vue"
import accountModalStructure from "@/data/view/accountStructure"
import GenerateServices from "@/services/GenerateServices"
import Input from "@/base/components/form/Input.vue"
import LoadingCard from "@/base/components/view/LoadingCard.vue"
import moment from "moment"

export default {
  name: "ticketmaster",
  components: {
    LoadingCard,
    Input,
    Description,
    Checkbox, TicketList, DateRangePicker, Button, TextBox,
    CenterContent, Alert, HistoryAction, BottomSheet, DataTable,
    LoadingModal, Row, TooltipLabel, SP
  },
  mixins: [PageMixin, pageProjectMixin, socketMixin],
  computed: {
    customActions() {
      return [
        {
          title: "View",
          click: this.onHistoryItemClick,
          isRendered: (item) => {
            return item.status === 'completed' && !item.result?.includes("any ticket")
          }
        },
        {
          title: "Retry",
          click: this.onHistoryItemRetryClick,
          isRendered: (item) => {
            return item.result?.includes("any ticket")
          }
        },
      ]
    },
    forceDisabled() {
      console.log(this.viewHelpers.includeOption, this.includeBarcode, !this.includeBarcode)
      if (this.viewHelpers.includeOption) {
        return !this.includeBarcode
      }
      return false
    },
  },
  data: () => ({
    queryLoading: false,
    accounts: null,

    fromDate: getTodayFormattedDate(),
    toDate: getDateWithPlusDays(7),
    timestamp: null,

    hasTBD: false,
    includeBarcode: false,

    // TextBox variables
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],

    // History variables
    historySheet: false,
    historyItems: [],
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },

    // Ticket items
    ticketItems: null,
    successMessage: null,
    fileBuffer: null,
    fileName: null,
    requestId: null,
    requestType: null,
    requestingPDF: false,
    requestingCloakify: false,
    disablePrint: false,

    // Generate
    generateOptions: {
      barcodeUnder: false,
      onlyMobile: false,
      hasAdditional: false,
      cloakify: false,
      hasBarcodeAdditional: false,
      post_letter: "",
    }
  }),
  methods: {
    onButtonClick() {
      this.resetHistoryId()
      this.warningError = null
      this.response.message = null
      this.textBoxError = ""
      this.textBoxErrorLines = []
      this.ticketItems = null
      this.successMessage = null

      let validateResponse = baseValidation(this.accounts, this.viewHelpers.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
        return
      }

      this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
      let data = this.getData(validateResponse.validLines)

      this.alert.loading = true
      this.alert.message = "Please stand by"

      ScrapeService.refresh({ data }).then(response => {
        if (response.data.data.request_status === "requested") {
          this.socketRequestId = response.data.data.request_id
          this.alert.message = "We got your request. Scraping tickets... \nYou can close this page and check it later from history. (" + response.data.data.request_id + ")"
        } else {
          this.warningError = "An error occurred. Please try again"
          this.alert.message = null
          this.alert.loading = false
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
        this.alert.message = null
        this.alert.loading = false
      })
    },
    onAccountChange(accounts) {
      this.accounts = accounts
    },

    getData(validAccounts) {
      let accounts = accountModalStructure(this.$route.meta.type, validAccounts)

      let barcode_included = this.viewHelpers.includeOption ? this.includeBarcode : true

      let model = {
        platform: this.platform,
        from_time: this.timestamp.from,
        to_time: this.timestamp.to,
        tbd_included: this.hasTBD,
        barcode_included: barcode_included,
        source: this.viewHelpers.source,
        request_type: this.viewHelpers.requestTypes,
        accounts: accounts,
        custom_data: {
          ...this.custom_data,
          type: "GENERATE_QR_ASYNC",
        },
      }

      model = Object.entries(model).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})

      return model
    },

    // Button Clicks
    onTestClick() {
      this.accounts = testAccounts
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    onCreatePO() {
      window.open("https://dogticketapps.com/po/create-po/?cacheId="+this.$route.query.history, '_blank');
    },
    onHistoryClose() {
      this.history.message = null
      this.historySheet = false
      this.historyItems = []
    },
    decorateHistoryData(items) {
      return items?.map(item => ({
        request_id: item.request_uuid,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        account: item.request_data?.accounts?.map(account => {
          let ticketMaster = account.account_team + ":" + account.account_user
          let seatGeek = account.account_performer
          return (item.request_data.platform === 'seatgeek') ? seatGeek : ticketMaster
        }).join("<br />"),
        time_range: `From: ${this.formattedDate(item.request_data.from_time)}<br/>To: ${this.formattedDate(item.request_data.to_time)}`,
        TBD: item.request_data.tbd_included,
        result: item.api_response_message,
        result_tooltip: item.api_response_errors?.map(error => `${error?.account_user || ""} -> ${error?._error_message}`).join("<br />"),
        platform: item.request_data.platform,

        status: item.request_status,
        request_time: this.printableDate(item.created_at),
        action: "",

        _item: item,
        _search: JSON.stringify([item.request_data, item?.decorated_response, item.extra_data]),
      }))
    },
    onHistoryItemClick(item) {
      this.checkParams(item._item)
      this.response.message = null
      let id = typeof item === 'object' ? item._item.request_uuid : item
      this.onHistoryClose()
      this.alert.loading = true
      this.alert.message = "Please stand by"
      this.getTicketList(id)
    },
    onHistoryItemRetryClick(item) {
      this.queryLoading = true
      this.checkParams(item._item)
      this.onHistoryClose()
    },
    getTicketList(id) {
      RequestService.historyDetail(id).then(response => {
        if (this.alert.loading === false) {
          return
        }

        if (response.data.data === null) {
          this.response.message = "Invalid or deleted history log."
          return
        }

        // Apply params
        if (this.$route.query.history && response.data?.data?.request_type !== "WEB_PRINT_TICKET") {
          this.checkParams(response.data.data)
        }

        // Disable print ticket if it has been more than 48 hours
        if (response.data.data?.created_at) {
          let now = moment(new Date())
          let createdAt = moment(response.data.data?.created_at)
          let hours = moment.duration(now.diff(createdAt)).asHours()
          this.disablePrint = hours > 48
        }

        this.requestId = response.data.data?.request_id || response.data.data?.request_uuid || "no-request-id"
        let data = response.data.data.decorated_response

        if (Array.isArray(data) && data.length > 0 && response.data.data.request_status !== 'failed') {
          this.ticketItems = data
          if (response.data.data?.api_response_errors?.length > 0) {
            this.response.message = this.getDetailedError(response, true)
          }
        } else if (response.data.code === 100) {
          this.ticketItems = null
          this.response.message = this.getDetailedError(response)
        } else {
          this.response.message = "An error occurred. Please try again."
        }

        this.alert.message = null
        this.alert.loading = false
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.message = null
        this.alert.loading = false
      })
    },
    onGenerateClick(selectedRows) {
      this.generateOptions.hasBarcodeAdditional = this.generateOptions.post_letter !== ""

      let model = {
        platform: this.platform,
        list: selectedRows.map( ({ _search, ...keepSelected }) => keepSelected),
        ...this.generateOptions,
        custom_data: {
          ...this.custom_data,
          cloakify: this.generateOptions.cloakify,
          title: `${this.custom_data.title} / Generate QRs`,
          type: "generate"
        }
      }

      this.requestingCloakify = (this.generateOptions.cloakify === true)
      this.response.message = null
      this.successMessage = null
      this.alert.message = "Loading..."
      this.alert.loading = true
      this.scrollToTop()

      this.isGenerate = true
      GenerateServices.generateWithQueue(model).then(response => {
        this.requestType = null
        this.handleSocketResponse(response.data.data, "Generating tickets...")
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }

        this.alert.message = null
        this.alert.loading = false
      })
    },

    // Picker actions
    onPicker(from, to, timestamp) {
      this.timestamp = timestamp
      this.fromDate = from
      this.toDate = to
    },
    onToDateReset() {
      this.toDate = addDays(this.fromDate, 7)
    },

    onCSVClick(items) {
      const filteredList = this.viewHelpers.prepareCSVItems(items)

      let data = {
        platform: this.platform,
        list: filteredList,
        custom_data: {
          ...this.custom_data,
          title: `${this.custom_data.title} / Generate CSV`,
          type: "generateCSV"
        }
      }

      this.successMessage = null
      this.alert.message = "Loading..."
      this.alert.loading = true
      this.requestingCloakify = false
      this.scrollToTop()

      GenerateServices.generateCSV(data).then(response => {
        this.response = response.networkResponse(true)
        this.requestType = "GENERATE_CSV"
        this.onGenerateResponse(response.data)
        this.alert.message = null
        this.alert.loading = false
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }

        this.alert.message = null
        this.alert.loading = false
      })
    },
    onPDFClick(items) {
      let prepareData = {}
      items.forEach(i => {
        if (prepareData[i.account_user]) {
          prepareData[i.account_user] = {
            ...prepareData[i.account_user],
            account_tickets: prepareData[i.account_user].account_tickets.concat([i.event_id, i.section, i.row, i.seat].join("."))
          }
        } else {
          prepareData[i.account_user] = {
            account_user: i.account_user,
            account_team: i.team,
            account_tickets: [[i.event_id, i.section, i.row, i.seat].join(".")]
          }
        }
      })

      this.successMessage = null
      this.response.message = null
      this.alert.message = "Loading..."
      this.alert.loading = true
      this.requestingPDF = true
      this.scrollToTop()

      let data = {
        accounts: Object.values(prepareData),
        request_id: this.requestId,
        custom_data: {
          ...this.custom_data,
          title: `${this.custom_data.title} / Print Tickets`,
          type: "Print"
        }
      }

      GenerateServices.generatePDF(data).then(response => {
        if (response.data.data.request_status === "requested") {
          this.socketRequestId = response.data.data.request_id
          this.alert.message = "We got your request. Printing tickets... \nYou can close this page and check it later from history. (" + response.data.data.request_id + ")"
        } else {
          this.warningError = "An error occurred. Please try again"
          this.alert.message = null
          this.alert.loading = false
        }
        this.scrollToTop()
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
        this.alert.message = null
        this.alert.loading = false
      })
    },
    // Generate response
    onGenerateResponse(response) {
      if (response.code !== 100) {
        this.response.variant = 'error'
        this.response.message = response.message
        return
      }

      this.fileBuffer = response.data.request_id
      this.fileName = response.data.file_name
      if (this.requestType === null) {
        if (response.data.file_list.length > 0) {
          this.successMessage = "QR Generated Successfully "
          if (this.generateOptions.cloakify) {
            this.successMessage += " including Cloakify format"
          }
        }
        if (response.data.not_created_list.length > 0) {
          this.response.variant = 'error'
          this.response.message = 'There are some errors: <br />'
          this.response.message += response.data.not_created_list.map(item => `${item.account_user} -> ${item.section}.${item.row}.${item.seat}: ${item.error_message}`).join("<br />")
        }
      } else if (this.requestType === 'GENERATE_CSV') {
        this.successMessage = "CSV Generated Successfully"
      }
      this.scrollToTop()
    },
    downloadFile(useCloakify) {
      this.response.message = null
      this.alert.message = "Please stand by"

      let request = [
        GenerateServices.download(this.fileBuffer, this.requestType),
      ]
      if (!!useCloakify) {
        request = [
            GenerateServices.download(this.fileBuffer + "_cloakify", this.requestType)
        ]
      }

      Promise.all(request).then(responses => {
        responses.forEach( (response, index) => {
          let fileName = !!useCloakify ? this.fileBuffer + "_cloakify.zip" : (this.fileName ?? this.fileBuffer)
          let reader = new FileReader()
          reader.onload = () => {
            let type = this.requestType === 'GENERATE_CSV' ? 'text/csv' : 'application/zip'
            let blob = new Blob([response.data], { type })
            let url = window['URL'].createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
          }
          reader.readAsBinaryString(response.data)
        })
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.message = null
      })
    },
    downloadCloakify() {
      this.downloadFile(true)
    },
    onSocketData(data) {
      if (data.status === 'failed') {
        this.alert.message = null
        this.alert.loading = false
        this.response.variant = 'error'
        this.response.message = this.getSocketError(data)
      } else {
        if (this.requestingPDF === false) {
          this.getTicketList(data.request_id)
        } else {
          this.alert.message = null
          this.alert.loading = false
          this.requestingPDF = false
          this.requestingCloakify = false

          this.requestType = "WEB_PRINT_TICKET"
          this.fileBuffer = data.request_id
          if (data.status === 'completed') {
            this.successMessage = data.response_message
          } else {
            this.response.message = this.getSocketError(data)
          }
          if (data.response_errors?.length > 0) {
            this.response.message = data.response_message
            let errorMessages = data.response_errors.map(err => `${err.account_user ?? ""} -> ${err._error_message}`).join("<br />")
            this.response.message += "<br/>" + errorMessages
          }
        }
      }
    },

    // Redirection Handler
    checkParams(item) {
      if (this.$route.query.accounts) {
        this.queryLoading = true
        this.applyQueryParams({
          accounts: this.$route.query.accounts,
          fromDate: this.$route.query.from_time,
          toDate: this.$route.query.to_time,
          hasTBD: this.$route.query.has_tbd
        })
      } else if (item) {
        let accounts = item.platform === 'seatgeek' ?
            item.request_data.accounts.map(acc => [acc.account_performer, acc.account_token].join(":")).join("\n") :
            item.request_data.accounts.map(acc => [acc.account_team, acc.account_user, acc.account_password].join(":")).join("\n")

        this.applyQueryParams({
          accounts: accounts,
          fromDate: item.request_data.from_time,
          toDate: item.request_data.to_time,
          hasTBD: item.request_data.tbd_included || false,
          includeBarcode: item.request_data.barcode_included || false,
        })
      }
    },
    applyQueryParams({ accounts, fromDate, toDate, hasTBD, includeBarcode }) {
      this.warningError = null
      this.response.message = null
      this.textBoxError = ""
      this.textBoxErrorLines = []
      this.ticketItems = null
      this.successMessage = null

      this.accounts = accounts
      this.delay(() => {
        this.fromDate = getFormattedDate(fromDate * 1000)
        this.delay(() => {
          this.hasTBD = (typeof hasTBD === 'boolean') ? hasTBD : ((hasTBD === "true") || false)
          this.includeBarcode = (typeof includeBarcode === 'boolean') ? includeBarcode : ((includeBarcode === "true") || false)
          this.toDate = getFormattedDate(toDate * 1000)
          this.queryLoading = false
        })
      })
    }
  },
  created() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel

    this.generateOptions.onlyMobile = this.viewHelpers.ticketGenerateValues.onlyMobile
    this.generateOptions.barcodeUnder = this.viewHelpers.ticketGenerateValues.barcode
    this.generateOptions.hasAdditional = this.viewHelpers.ticketGenerateValues.additional
    this.generateOptions.cloakify = this.viewHelpers.ticketGenerateValues.cloakify
  },
  mounted() {
    this.checkParams()
  }
}
</script>
