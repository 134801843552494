<template>
  <v-app-bar app dense elevation="3">
    <vs-navbar padding-scroll center-collapsed :not-line="!isNavbar" v-model="active" class="py-1">

      <template #left>
        <slot name="custom">
          <div class="w700">
            <v-skeleton-loader class="pl-2" type="list-item-avatar" :loading="response.loading">
              <Picker @onPlatformChange="onPlatformChange" class="d-inline" :items="platforms" />
              <sp span is-button @onClick="onTitleClick" class="font-weight-black headline d-inline centerVertical">
                {{ title }}
              </sp>
            </v-skeleton-loader>
          </div>
        </slot>
      </template>

      <!-- Right slot -->
      <template #right>
        <slot name="right-custom" :active="active">
        </slot>
      </template>

    </vs-navbar>
  </v-app-bar>
</template>

<script>
import router from "@/router"
import routeMixin from "@/base/data/mixins/routeMixin"
import sp from "@/base/components/container/SP"
import Picker from "@/base/view/action/Picker"
import Button from "@/base/components/form/Button"
import ossNavbarItems from "@/data/navbar"
import {findTitleById} from "@/utils/routingHelper"
import pageMixin from "@/base/data/mixins/pageMixin"

export default {
  name: "Navbar",
  components: {Button, Picker, sp},
  mixins: [routeMixin, pageMixin],
  data: () => ({
    ossNavbarItems,
    title: "",
    platformTitle: "",
    platformPage: "",
  }),
  props: {
    platforms: {
      type: Array,
      required: null,
      default: () => [],
    },
    hasPicker: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    onTitleClick() {
      this.active = null
      router.push({ name: 'root' })
    },
    onPlatformChange(platform) {
      this.platformTitle = platform.platform_name
      this.setPageTitle()
    },
    setPageTitle() {
      if (this.platformPage !== "" && this.platformPage !== "Home") {
        this.title = this.platformTitle + " / " + this.platformPage
      } else {
        this.title = this.platformTitle
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.platformPage = findTitleById(to.name)
        this.setPageTitle()
      }
    },
    platforms: {
      immediate: true,
      handler(to) {
        if (to.length === 0 && this.hasPicker) {
          this.response.loading = true
        } else if (to.length > 0) {
          this.response.loading = false
        }
      }
    }
  },
  mounted() {
    this.setPageTitle()
  }
}
</script>

<style scoped>
.w700 {
  width: 700px;
  min-width: 700px;
  margin-left: 50px;
}
.centerVertical {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>