import types from "@/data/types"

const tableSort = (type) => {
    switch (type) {
        case types.BALLPARK_APP:
            return ['date', 'section', 'row', 'seat']
        case types.OSS_APP:
        case types.OSS_API:
        case types.OSS_WEB:
            return ['timestamp', 'section', 'row', 'seat']
        default:
            return ['ticket_id']
    }
}

export default tableSort