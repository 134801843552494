import Vue from 'vue'

export default {
    namespaced: true,
    state() {
        return {
            items: [],
            all: [],
            allowed: [],
            myPages: [],
        }
    },
    getters: {
        page: function (state) {
            return state
        },
        myPages: function (state) {
            return state.myPages ?? []
        },
    },
    mutations: {
        SET_PAGE_ITEMS(state, items) {
            state.items = items
        },
        SET_ALL_PAGES(state, all) {
            state.all = all
        },
        SET_ALLOWED_PAGES(state, allowed) {
            state.allowed = allowed
        },
        SET_MY_PAGES(state, myPages) {
            state.myPages = myPages
        }
    },
    actions: {
        setPages({ commit }, { allPages, allowedPages, pageItems }) {
            // Add cookies
            Vue.$cookies.set('all', allPages)
            Vue.$cookies.set('allowed', allowedPages)
            Vue.$cookies.set('items', pageItems)

            // Commit actions
            commit('SET_ALL_PAGES', allPages)
            commit('SET_ALLOWED_PAGES', allowedPages)
            commit('SET_PAGE_ITEMS', pageItems)
        },
        setMyPages({ commit }, { myPages }) {
            Vue.$cookies.set('myPages', myPages)

            commit('SET_MY_PAGES', myPages)
        },
        clearRoles({ commit }) {
            commit('SET_PAGE_ITEMS', [])
            commit('SET_ALL_PAGES', [])
            commit('SET_ALLOWED_PAGES', [])
            commit('SET_MY_PAGES', [])
        }
    },
}
