import baseProps from "@/base/data/props/base"

const ticketListProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null,
    },
    items: {
        type: Array,
        required: false,
        default: () => [],
    },
    selectable: {
        type: Boolean,
        required: false,
        default: true,
    },
    filterable: {
        type: Boolean,
        required: false,
        default: true,
    },
}

export default ticketListProps
