import types from "@/data/types"

const ticketParser = (type, items) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_WEB:
        case types.OSS_APP:
            return items?.map( (item, index) => ({
                id: index,
                team: item.__event_id_unique?.split(/[0-9]/)[0],
                account_user: item.account_user,
                event_id: item.__event_id_unique?.replace(item.__event_id_unique?.split(/[0-9]/)[0], ''),
                event: item.event_detail.__event_description,
                alternative: item.event_detail?.__event_name,
                date: item.event_detail?.__event_date_year === '2999' ? 'TBD' : item.event_detail.__event_date_us_format,
                time: item.event_detail?.__event_date_year === '2999' ? 'TBD' : item.event_detail?.__event_time_us_format,
                timestamp: item.event_detail.__event_date_timestamp_local,
                section: item.__ticket_section,
                row: item.__ticket_row,
                seat: item.__ticket_seat,
                barcode: item.__ticket_barcode,
                additional: item.__ticket_entry_gate,
                ticket_id: item.__ticket_id_unique,
                _item: item,
                _search: JSON.stringify(item),
            }))
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
            return items?.map( (item, index) => ({
                id: index,
                team: item.__event_id_unique?.split(/[0-9]/)[0],
                account_user: item.account_user,
                event_id: item.__event_id_unique?.replace(item.__event_id_unique.split(/[0-9]/)[0], ''),
                event: item.event_detail.__event_description,
                alternative: item.event_detail?.__event_name,
                date: item.event_detail?.__event_date_us_format,
                time: item.event_detail.__event_time_us_format,
                timestamp: item.event_detail.__event_date_timestamp_local,
                section: item.__ticket_section,
                row: item.__ticket_row,
                seat: item.__ticket_seat,
                _item: item,
                _search: JSON.stringify(item),
            }))
        case types.SG:
            return items?.map( (item, index) => ({
                id: index,
                account_user: item.account_user.slice(0, 4) + "****" +  item.account_user.slice(-4),
                account_user_tooltip: item.account_user,
                date: item.event_detail.__event_date_us_format,
                time: item.event_detail.__event_time_us_format,
                timestamp: item.event_detail.__event_date_timestamp_local,
                section: item.__ticket_section,
                row: item.__ticket_row,
                seat: item.__ticket_seat,
                event_id: item.event_detail.__event_id,
                team: item.event_detail.__event_host,
                event: item.event_detail?.__event_name,
                year: item.event_detail.__event_date_year,
                venue: item.venue_detail.__venue_name,
                barcode: item.__ticket_barcode,
                info: item.ticket_section_notes,
                _item: item,
                _search: JSON.stringify(item),
            }))
        case types.OSS_CREDITS:
            return items?.map( (item, index) => ({
                id: index,
                account_user: item.account_user,
                account_team: item.team,
                credit_amount: `${item.__account_credit_currency} ${item.__account_credit_amount}`,
                resale_credit:`${item.__resale_credit_currency} ${item.__resale_credit_amount}`,
                _item: item,
                _search: JSON.stringify(item),
            }))
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return items?.map( (item, index) => ({
                id: index,
                account_user: item.account_user,
                account_number: item.__ticket_qr_data.account_id,
                date: item.event_detail?.__event_date_us_format,
                time: item.event_detail?.__event_time_us_format,
                timestamp: item.event_detail.__event_date_timestamp_local,
                section: item.__ticket_section,
                row: item.__ticket_row,
                seat: item.__ticket_seat,
                event_id: item.event_detail.__event_id,
                teams: item.event_detail?.__event_name,
                alternative: "",
                year: item.event_detail.__event_date_year,
                venue: item.venue_detail.__venue_name,
                barcode: item.__ticket_barcode,
                ticket_id: item.__ticket_id_unique,
                _item: item,
                _search: JSON.stringify(item),
            }))
        default:
            console.info("No ticket parser for the type: ", type)
            return items
    }
}

export default ticketParser
