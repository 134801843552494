import types from "@/data/types"

const descriptions = (type) => {
    switch (type) {
        case types.OSS_API:
            return "OSS API is using mobile api from depreciated oss.ticketmaster.com."
        case types.OSS_APP:
            return "AM APP is using AM teams' own apps, may be slower and may have inconsistent number of tickets. Currently only available for the following teams: "
        case types.OSS_WEB:
            return "AM WEB is using am.ticketmaster.com/team, may be slower."
        case types.OSS_API_INVENTORY:
            return "OSS API is using mobile api from depreciated oss.ticketmaster.com."
        case types.OSS_APP_INVENTORY:
            return "OSS APP is using AM teams' own apps. Currently only available for the following teams: "
        case types.OSS_WEB_INVENTORY:
            return "OSS WEB is using am.ticketmaster.com/team, may be slower."
        case types.TM_PHONE:
            return " Write one PhonePass link per line.  It doesn’t use any ticket platform. "
        case types.PK_PASS:
            return "Upload a zip file containing the PhonePass files. It doesn’t use any ticket platform."
        case types.XLS_TO_QR:
            return "You can upload a CSV or XLSX file. It doesn’t use any ticket platform. "
        case types.TM_INVOICES:
            return "OSS WEB is using am.ticketmaster.com/team, may be slower."
        case types.OSS_RESET:
            return "OSS API is using mobile api from depreciated oss.ticketmaster.com. Pass reset is not a stable endpoint, ticketmaster may give false success."
        case types.BALLPARK_APP:
            return "MLB APP is using ballpark mlb app. Add \"all\" as team to refresh the entire account."
        case types.BALLPARK_WEB:
            return "MLB WEB is using tickets.com website. Currently not working. Please try later."
        case types.BALLPARK_VERIFY:
            return "Verifies an email on a ballpark account by using MLB APP"
        case types.BALLPARK_CREATE:
            return "Creates new ballpark mlb account by using mlb.com"
        case types.SG:
            return "SeatGeek API is using their official API."
        case types.XLS_TO_QR_SG:
            return "You can upload a CSV or XLSX file. It doesn’t use any ticket platform. "
        case types.OSS_CREDITS:
            return "OSS WEB is using am.ticketmaster.com/team, may be slower."
        default:
            return ""
    }
}

export default descriptions