import baseProps from "@/base/data/props/base"

const spProps = {
    ...baseProps,
    isButton: {
        type: Boolean,
        required: false,
        default: false,
    },
    span: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default spProps