import baseProps from "@/base/data/props/base"
import SelectType from "@/base/data/types/selectType"

const selectProps = {
    ...baseProps,
    items: {
        type: Array,
        required: false,
        default: () => [],
    },
    required: {
        type: Boolean,
        required: false,
        default: true,
    },
    label: {
        type: String,
        required: false,
        default: "Select"
    },
    type: {
        type: Number,
        required: false,
        default: SelectType.Default,
    },
    sorted: {
        type: Boolean,
        required: false,
        default: false,
    },
    custom: {
        type: Boolean,
        required: false,
        default: false,
    },
}

export default selectProps