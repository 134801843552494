import baseProps from "@/base/data/props/base"

const textBoxProps = {
    ...baseProps,
    value: {
        type: String,
        required: false,
        default: "",
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    label: {
        type: String,
        required: true,
    },
    hint: {
        type: String,
        required: false,
        default: "",
    },
    rules: {
        type: Array,
        required: false,
        default: () => [],
    },
    error: {
        type: String,
        required: false,
        default: "",
    },
}

export default textBoxProps