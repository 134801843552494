/**
 * CAUTION:
 * DO NOT CHANGE ANY NAME
 * IT'S BASED ON DATABASE VALUES
 */
const qrMenuItems = [
    {
        id: '/',
        name: 'root',
        icon: 'bx bx-home',
        title: 'Home',
        isRoot: true,
    },
    {
        id: 'ticketmaster',
        name: 'ticketmaster',
        image: 'oss.png',
        title: 'TicketMaster',
        isRoot: true,
        child: [
            {
                id: '/ticketmaster/am-app',
                name: 'oss-app',
                icon: 'bx bx-list-plus',
                title: 'AM APP',
            },
            {
                id: '/ticketmaster/am-web',
                name: 'oss-web',
                icon: 'bx bx-list-plus',
                title: 'AM WEB',
            },
            {
                id: '/ticketmaster/oss-web-invoices',
                name: 'oss-web-invoices',
                icon: 'bx bx-receipt',
                title: 'Pull AM Invoices',
            },
            {
                id: '/ticketmaster/oss-credits',
                name: 'oss-credits',
                icon: 'bx bx-receipt',
                title: "AM Credits in Account",
            },
            // ---
            // {
            //     id: '/ticketmaster/oss-pass-reset',
            //     name: 'oss-pass-reset',
            //     icon: 'bx bx-reset',
            //     title: 'OSS Reset Password',
            // },
            // ---
            {
                id: '/ticketmaster/spreadsheet-to-qr',
                name: 'spreadsheet-to-qr',
                icon: 'bx bx-qr',
                title: 'Spreadsheet upload to QR',
            },
            {
                id: '/ticketmaster/pkpass-upload',
                name: 'pkpass-upload',
                icon: 'bx bx-barcode',
                title: 'iPhone PKPass to PDF-QR',
            },
            {
                id: '/ticketmaster/pkpass-link',
                name: 'pkpass-link',
                icon: 'bx bx-mobile',
                title: 'Wallet Links to QR',
            },
        ],
    },
    {
        id: 'ballpark',
        name: 'ballpark',
        image: 'ballpark.png',
        title: 'BallPark',
        isRoot: true,
        child: [
            {
                id: '/ballpark/mlb-app-to-qr',
                name: 'mlb-app-to-qr',
                icon: 'bx bx-list-ol',
                title: 'BallPark to QR',
            },
            {
                id: '/ballpark/mlb-web-to-qr',
                name: 'mlb-web-to-qr',
                icon: 'bx bx-list-ol',
                title: 'MLB WEB',
            },
            {
                id: '/ballpark/mlb-app-verify',
                name: 'mlb-app-verify',
                icon: 'bx bx-link',
                title: 'Ballpark Verify Email',
            },
            {
                id: '/ballpark/mlb-create-account',
                name: 'mlb-create-account',
                icon: 'bx bx-plus',
                title: 'MLB Create Account',
            },
        ],
    },
    {
        id: 'seatgeek',
        name: 'seatgeek',
        image: 'seatgeek.png',
        title: 'SeatGeek',
        isRoot: true,
        child: [
            {
                id: '/seatgeek/seatgeek-api',
                name: 'seatgeek-api',
                icon: 'bx bx-list-ol',
                title: 'SeatGeek to QR',
            },
            {
                id: '/seatgeek/sg-spreadsheet-to-qr',
                name: 'sg-spreadsheet-to-qr',
                icon: 'bx bx-qr',
                title: 'SG Spreadsheet to QR',
            },
        ],
    },
]

export default qrMenuItems
