import baseProps from "@/base/data/props/base"
import {getDateWithPlusMonths, getTodayDate} from "@/base/helper/date"

const dateRangePickerProps = {
    ...baseProps,
    startingDate: {
        type: String,
        required: false,
        default: getTodayDate()
    },
    endingDate: {
        type: String,
        required: false,
        default: getDateWithPlusMonths(6)
    },
    startPlaceholder: {
        type: String,
        required: false,
        default: "Start date",
    },
    endPlaceholder: {
        type: String,
        required: false,
        default: "End date",
    },
    fromDate: {
        type: String,
        required: false,
        default: null,
    },
    toDate: {
        type: String,
        required: false,
        default: null,
    },
}

export default dateRangePickerProps