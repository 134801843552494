import idle from "@/base/store/idle"

const vuePersist = {
    setState: (key, state, storage) => {
        idle(() => {
            storage.setItem(key, JSON.stringify(state))
        })
    }
}

export default vuePersist