import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import vuePersist from "@/base/store/persist"
import VueCookies from "vue-cookies"
import auth from "@/base/store/module/auth"
import app from "@/base/store/module/app"

Vue.use(Vuex)
Vue.use(VueCookies)

const init = (modules) => {
    let defaultModules = {
        ...modules,
        auth,
        app,
    }

    return new Vuex.Store({
        modules: defaultModules,
        strict: process.env.NODE_ENV !== 'production',
        plugins: [createPersistedState(vuePersist)],
    })
}

export default init
