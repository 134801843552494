import types from "@/data/types"

const textBoxModels = (type) => {
    switch (type) {
        case types.OSS_API:
            return "rams:user101@gmail.com:pass692\nrams:userr102@gmail.com:pass693\nrams:user103@gmail.com:pass104"
        case types.OSS_APP:
        case types.OSS_WEB:
            return "nygiants:user104@gmail.com:pass1541"
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.OSS_CREDITS:
            return "rams:user102@gmail.com:pass102"
        case types.TM_PHONE:
            return "https://downloadmyseats.com/orders/cd20b8a21398444daaf10a893510426f/index.html"
        case types.PK_PASS:
            return ""
        case types.TM_INVOICES:
            return "nygiants:user103@yahoo.com:pwpassword12"
        case types.OSS_RESET:
            return "nyyreg:usertest@gmail.com"
        case types.SG:
            return "2066:23d5560e0ed910203bddf2b491e658f91d69e0a3172|11211481|1678890527"
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return "giants:myuser@yahoo.com:spassftgd"
        case types.BALLPARK_LINK:
            return "Please enter MLB account credentials: (email:password:team:target_email)"
        case types.BALLPARK_CREATE:
            return "new_user@gmail.com:A42z02cf:jack:london"
        case types.BALLPARK_VERIFY:
            return "account_user@gmail.com:account_password:verify_it@gmail.com"
        default:
            return type
    }
}

export default textBoxModels
