import baseProps from "@/base/data/props/base"

const alertProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    message: {
        type: String,
        required: false,
        default: null
    },
    color: {
        type: String,
        required: false,
        default: "primary"
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    value: {
        type: Number,
        required: false,
        default: null,
    }
}

export default alertProps
