const spreadsheetTypes = {
    "ticketmaster": [
        "Section",
        "Row",
        "Seat",
        "Barcode"
    ],
    "ticketmasterV2": [
        "Section",
        "Row",
        "Seat",
        "Barcode"
    ],
    "seatgeek": [
        "Section",
        "Row",
        "Seat",
        "Barcode",
        "Date",
        "Time",
        "Venue",
        "Event Title",
        "Performer ID",
        "Post Letter",
    ],
    "match": {
        "Barcode": "QR",
        "Event Title": "Event",
        "Performer ID": "Performer",
    },
}

export default spreadsheetTypes

