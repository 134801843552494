import baseProps from "@/base/data/props/base"

const fileUploadProps = {
    ...baseProps,
    accept: {
        type: String,
        required: false,
        default: ".csv, .xls, .xlsx, .txt, .png"
    },
    label: {
        type: String,
        required: false,
    },
    hint: {
        type: String,
        required: false,
    },
    onFileRead: {
        type: Function,
        required: false,
    },
}

export default fileUploadProps