<template>
  <div v-bind:class="computedDivClass">
    <v-btn
      :loading="loading"
      :disabled="computedDisabled"
      @click="onClick"
      v-bind:class="computedClass"
      v-bind="$attrs">
      <v-icon v-if="prependIcon" class="ml-1">{{ prependIcon }}</v-icon>
        {{ title }}
      <v-icon v-if="buttonIcon" class="ml-1">{{ buttonIcon }}</v-icon>
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
import buttonProps from "@/base/data/props/button"
import componentMixin from "@/base/data/mixins/componentMixin"

export default {
  name: "Button",
  props: buttonProps,
  emits: ['onClick'],
  mixins: [componentMixin],
  computed: {
    preDefinedClasses() {
      if (this.$attrs.small === '') {
        return ['no-uppercase']
      }
      return ['no-uppercase', 'font-weight-bold']
    },
    computedDisabled() {
      if (this.$attrs.disabled === null) {
        return this.loading
      }

      return this.$attrs.disabled
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
.v-btn.v-size--large {
  font-size: 1rem;
}
</style>