import baseProps from "@/base/data/props/base"

const checkboxProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: "",
    },
    custom: {
        type: Boolean,
        required: false,
        default: false,
    },
    color: {
        type: String,
        required: false,
        default: "primary",
    },
}

export default checkboxProps