export const menuItems = {
    // 'OSS API': 'oss-api',
    'AM APP': 'oss-app',
    'AM WEB': 'oss-web',

    // 'OSS API Inventory': 'oss-api-inventory',
    // 'OSS APP Inventory': 'oss-app-inventory',
    // 'OSS WEB Inventory': 'oss-web-inventory',

    'OSS WEB Invoices': 'oss-web-invoices',
    'OSS WEB Credits': 'oss-credits',
    // 'OSS Reset Password': 'oss-pass-reset',

    'Spreadsheet to QR': 'spreadsheet-to-qr',
    'iPhone PKPass to PDF-QR': 'pkpass-upload',
    'Wallet Links to Barcode List': 'pkpass-link',

    // MLB
    'Ballpark to QR': 'mlb-app-to-qr',
    'MLB WEB': 'mlb-web-to-qr',
    'Ballpark Verify Email': 'mlb-app-verify',
    'MLB Create Account': 'mlb-create-account',

    // SeatGeek
    'SeatGeek API': 'seatgeek-api',
    'SG Spreadsheet to QR': 'sg-spreadsheet-to-qr',
}