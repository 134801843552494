<template>
<div v-bind:class="computedDivClass">
  <div v-bind:class="computedClass">
    <!-- Content -->
    <span :id="id" v-if="left" v-bind:class="{ 'copyable-content-wrapper': true, 'd-none': hidden }">
      <slot></slot>
    </span>

    <!-- Button -->
    <slot name="button">
      <v-tooltip v-if="tooltip" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click.stop class="pa-0 ma-0 btn-rm-width" text @click="onClick"><v-icon small>{{ icon }}</v-icon></v-btn>
        </template>
        {{ tooltipText }}
      </v-tooltip>
      <v-btn v-else text @click="onClick"><v-icon small>{{ icon }}</v-icon></v-btn>
    </slot>

    <!-- Content -->
    <span v-if="left === false" v-bind:class="{ 'copyable-content-wrapper': true, 'd-none': hidden }">
      <slot></slot>
    </span>
  </div>
  <v-snackbar
    :timeout="snackbarTimeout"
    v-model="snackbar"
    absolute
    bottom
    color="primary"
    right
    text>
    {{ snackbarText }}
  </v-snackbar>
</div>
</template>

<script>
import ComponentMixin from "@/base/data/mixins/componentMixin"
import copyableProps from "@/base/data/props/copyable"
import {copyToClipboard, getUniqueID, isHTML} from "@/base/helper/utils"

export default {
  name: "Copyable",
  components: {},
  mixins: [ComponentMixin],
  props: copyableProps,
  data: () => ({
    snackbar: false,
  }),
  methods: {
    onClick() {
      let content = this.$el.getElementsByClassName("copyable-content-wrapper")[0].innerHTML
      copyToClipboard(this.id, () => {
        if (this.noSnackbar === false) {
          this.snackbar = true
        }
      })
    }
  },
  computed: {
    id() {
      return getUniqueID()
    }
  },
}
</script>

<style scoped>
.btn-rm-width {
  min-width: 24px !important;
}
</style>
