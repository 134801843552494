export const requiredRule = (name) => [
    v => !!v || `${name} is required`,
]

export const emailRule = [
    v => !!v || 'E-mail is required',
    v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
]

export const passwordRule = (limit) => [
    v => !!v || 'Password is required',
    v=> (v || '').length <= limit || 'Max exceeded'
]

export const passwordRuleMinMax = (min, max) => [
    v => !!v || 'Password is required',
    v=> (v || '').length >= min || `Minimum ${min} characters`,
    v=> (v || '').length <= max || `Maximum ${max} characters`
]

export const numberRule = [
    v => !!v || 'Number is required',
    v => /^\d+$/.test(v) || 'This field only accept number',
]