<template>
<v-row :class="computedDivClass">
  <v-col cols="12" class="text-center align-center align-content-center">
    <slot></slot>
  </v-col>
</v-row>
</template>

<script>
import baseProps from "@/base/data/props/base"
import ComponentMixin from "@/base/data/mixins/componentMixin"

export default {
  name: "Row",
  props: baseProps,
  mixins: [ComponentMixin],
}
</script>