import Generic from "@/view/other/Generic";

export const genericRoutes = {
    path: '/:page',
    name: 'generic',
    component: Generic,
    meta: {
        auth: true,
        title: "Generic | User Dashboard"
    },
}
