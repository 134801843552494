import types from "@/data/types"

const textBoxLabels = (type) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_APP:
        case types.OSS_WEB:
            return "Example: cowboys:slink234@gmail.com:freelancer"
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.OSS_CREDITS:
            return "Example: cowboys:slink234@gmail.com:freelancer"
        case types.TM_PHONE:
            return "Example: https://downloadmyseats.com/orders/cd20b8a21398444daaf10a893510426f/index.html"
        case types.PK_PASS:
            return ""
        case types.TM_INVOICES:
            return "Example: nyyreg:shum87nyy:ggghhh"
        case types.OSS_RESET:
            return "Example: falcons:jayste12n@gmail.com"
        case types.SG:
            return "Example: 2069:ca865f117deadcba4c38023b6b366caa66658695a3172|11210481|1531167228"
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return "Example: giants:killebrewtyeryar@yahoo.com:sdfdftgd"
        case types.BALLPARK_LINK:
            return "Example: nguyeneloise@yahoo.com:2szcSb9c:dodgers:nguyeneloise@yahoo.com"
        case types.BALLPARK_CREATE:
            return "Example: emailaddress@email.com:password:John:Murray"
        case types.BALLPARK_VERIFY:
            return "Example: emailaddress@email.com:password:mail@mail.com"
        default:
            return type
    }
}

export default textBoxLabels
