import Vue from 'vue'

export default {
    namespaced: true,
    state() {
        return {
            allTeams: [],
            appTeams: [],
            teams: [],
        }
    },
    getters: {
        teams: function (state) {
            return state.teams ?? []
        },
        allTeams: function(state) {
            return state.allTeams ?? []
        },
        appTeams: function (state) {
            return state.appTeams ?? []
        }
    },
    mutations: {
        SET_TEAMS(state, teams) {
            state.teams = teams
        },
        SET_ALL_TEAMS(state, allTeams) {
            state.allTeams = allTeams
        },
        SET_APP_TEAMS(state, appTeams) {
            state.appTeams = appTeams
        }
    },
    actions: {
        setAllTeams({ commit }, ossTeams) {
            let appTeams = ossTeams.filter(team => team.oss_app === true).map(id => id.team_id)
            let teams = ossTeams.map(team => team.team_id).filter(id => id != null)

            Vue.$cookies.set('allTeams', ossTeams)
            Vue.$cookies.set('teams', teams)
            Vue.$cookies.set('appTeams', appTeams)

            commit('SET_ALL_TEAMS', ossTeams)
            commit('SET_APP_TEAMS', appTeams)
            commit('SET_TEAMS', teams)
        },

        logout({commit}) {
            // Remove cookies
            Vue.$cookies.remove('allTeams')
            Vue.$cookies.remove('teams')
            Vue.$cookies.remove('appTeams')

            // Commit actions
            commit('SET_ALL_TEAMS', null)
            commit('SET_TEAMS', null)
            commit('SET_APP_TEAMS', null)
        },
    },
}
