import Vue from "vue"

const path = "ballpark"

export default {
    verify(data) {
        return Vue.prototype.$http.post(`${path}/verify-email`, { data })
    },
    create(data) {
        return Vue.prototype.$http.post(`${path}/account`, { data })
    }
}
