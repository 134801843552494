<template>
<CenterContent size="xs12">
  <SP :class="['mb-3', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
  <v-card class="pa-2" outlined>
    <Sp class="text-h6 font-weight-bold">Caution:</Sp>
    <Sp class="text-subtitle-2 font-weight-regular">- Email address should be valid</Sp>
    <Sp class="text-subtitle-2 font-weight-regular">- Password should be at least 8 characters and one lowercase and one uppercase letter. But only alphanumeric characters are allowed.</Sp>
    <Sp class="text-subtitle-2 font-weight-regular">- First and Last Name can not be empty.</Sp>

    <TextBox
      :value="accounts"
      :label="viewHelpers.textBoxLabel"
      :hint="viewHelpers.textBoxHint"
      :error="textBoxError"
      :disabled="response.loading"
      :class-div-object="['mt-3']"
      @input="onAccountChange" />
    <Alert outlined type="error" border="left" :message="warningError" />
    <Alert outlined :type="response.variant" border="left" :message="response.message" />
    <v-row>
      <v-col cols="6">
        <Button
            title="Create Account"
            :loading="response.loading"
            :class-object="['px-10']"
            @onClick="onButtonClick"
            prepend-icon="bx bx-refresh"
            outlined />
      </v-col>
      <v-col cols="6">
        <Button
            title="Show My History"
            :loading="response.loading"
            :class-object="['px-10', 'float-right']"
            @onClick="onHistoryClick"
            prepend-icon="bx bx-history"
            outlined />
      </v-col>
    </v-row>
  </v-card>
  <BottomSheet
      v-model="historySheet"
      class="mb-10"
      @onClose="onHistoryClose">
    <Alert type="error" :message="history.message" />
    <DataTable
        title="History"
        custom-loading-type="table-thead, table-tbody, table-tfoot"
        filterable
        group-by="status"
        :loading="history.loading"
        :custom-options="datatableOptions"
        :custom-header-values="historyHeaderValues"
        :datatable-items="historyItems">
      <template v-slot:item.request="{ item }">
        <TooltipLabel v-if="item.request_tooltip && item.request_tooltip !== ''" top :label="item.request_tooltip">
          {{ item.request }}
        </TooltipLabel>
        <span v-else>{{ item.request }}</span>
      </template>

      <template v-slot:item.result="{ item }">
        <TooltipLabel v-if="item.result_tooltip && item.result_tooltip !== ''" top :label="item.result_tooltip">
          {{ item.result }}
        </TooltipLabel>
        <span v-else>{{ item.result }}</span>
      </template>
    </DataTable>
  </BottomSheet>
</CenterContent>
</template>

<script>
import CenterContent from "@/base/components/container/CenterContent"
import PageMixin from "@/base/data/mixins/pageMixin"
import helper from "@/utils/viewHelper"
import TextBox from "@/base/components/form/TextBox"
import {baseValidation} from "@/data/view/validate"
import Button from "@/base/components/form/Button"
import HistoryAction from "@/view/components/HistoryAction"
import Alert from "@/base/components/view/Alert"
import Sp from "@/base/components/container/SP"
import BallparkServices from "@/services/BallparkServices"
import BottomSheet from "@/base/components/container/BottomSheet.vue"
import TooltipLabel from "@/base/components/container/TooltipLabel.vue"
import DataTable from "@/base/components/container/DataTable.vue"
import RequestService from "@/services/RequestService"
import SP from "@/base/components/container/SP.vue";

export default {
  name: "ballparkCreateAccount",
  components: {SP, DataTable, TooltipLabel, BottomSheet, Sp, TextBox, CenterContent, Button, HistoryAction, Alert},
  mixins: [PageMixin],
  data: () => ({
    accounts: "",

    // TextBox variables
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],
    rules: {
      separator: ":",
      length: 4,
      items: ["email", "password_8", "required", "required"],
    },

    // History variables
    historySheet: false,
    historyItems: [],
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  }),
  methods: {
    onAccountChange(accounts) {
      this.accounts = accounts
    },

    // Button Clicks
    onButtonClick() {
      this.response.message = null
      this.warningError = null
      this.textBoxError = ""
      this.textBoxErrorLines = []

      let validateResponse = baseValidation(this.accounts, this.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
        return
      }

      let account = validateResponse.validLines[0]
      const [email, password, name, surname] = account.split(":")
      let data = {
        account_user: email,
        account_password: password,
        account_name: name,
        account_surname: surname,
        custom_data: {
          ...this.custom_data,
        },
      }

      this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
      this.response.loading = true

      BallparkServices.create(data).then(response => {
        this.response = response.networkResponse()
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else if (!!error.response.data.data) {
          let hasAccount = error.response.data.data?.api_response?.status === 'ACTIVE'
          let message = hasAccount ? `This account is already created at ${error.response.data.data.api_response.created}` : error.response.data.data.api_response.response
          this.response = {
            loading: false,
            code: 400,
            timestamp: new Date().getTime(),
            variant: 'error',
            message: message,
          }
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    decorateHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        request: item.request_data.account_user,
        request_tooltip: [item.request_data.account_user, item.request_data.account_name, item.request_data.account_surname].join(":"),
        result: !!item.decorated_response.response ? item.decorated_response.response?.slice(0, 10) + "..." : "No message",
        result_tooltip: item.decorated_response.response,
        status: item.request_status,
        request_time: this.printableDate(item.created_at),

        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    onHistoryClose() {
      this.historySheet = false
      this.historyItems = []
    },
    checkParams() {
      if (this.$route.query.account) {
        this.accounts = this.$route.query.account
      }
    },
  },
  mounted() {
    this.checkParams()
  },
  created() {
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel
  }
}
</script>
