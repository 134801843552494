import Vue from "vue"

const validateStructure = {
    separator: ":",
    length: 3,
    items: ["team", "email", "password"]
}

export const testAccounts = "nyyreg:jcakyuz@gmail.com:1\nnyyreg:jcakyuz@gmail.com:1123213\nnyyreg:jcakyuzgmail.com:1213123"

export const baseValidation = (text, validateStructure) => {
    if (text.trim().length === 0) {
        return {
            success: false,
            message: "Please enter a valid credentials: " + validateStructure.items.join(validateStructure.separator),
            other: "Missing keys",
        }
    }

    if (checkValidateStructure(validateStructure) === false) {
        return {
            success: false,
            message: "Please contact with developer. (Error code: 900)",
            other: "Missing keys",
        }
    }

    let lines = text.trim().split("\n")
    if (lines.length === 0) {
        return {
            success: false,
            message: "No valid line. (Error code: 901)",
            other: "Should be one line at least",
        }
    }

    let messages = []
    let errorLines = []
    lines.forEach( (line, index) => {
        let parts = []
        if (validateStructure.separator !== null) {
            parts = line.split(validateStructure.separator)
            if (parts.length !== validateStructure.length) {
                messages.push({
                    index: index,
                    message: "Line " + (index + 1) + ": Wrong format (" + line + ")"
                })
                errorLines.push(index)
            }
        } else {
            parts = [line]
        }

        parts.forEach( (part, partIndex) => {
            let validatePartKey = validateStructure.items[partIndex]

            /// Email & mail
            if (validatePartKey === 'email' || validatePartKey === 'mail') {

                if (validateEmail(part) === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": Wrong e-mail (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            /// Password -
            /// Default min: 2, Also supports minimum & maximum
            if (validatePartKey.startsWith('password')) {
                if (part.length <= 2) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": Password is wrong (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                } else if (validatePartKey.includes("_")) {
                    let minLength = validatePartKey.split("_")[1]
                    let maxLength = validatePartKey.split("_")[2] ?? minLength + 2

                    if (part.length < minLength) {
                        messages.push({
                            index: index,
                            message: `Line ${index+1}: Password should be ${minLength} character at least. (${line})`
                        })
                        errorLines.push(index)
                        return
                    }

                    if (part.length > maxLength) {
                        messages.push({
                            index: index,
                            message: `Line ${index+1}: Password should be ${maxLength} character at least. (${line})`
                        })
                        errorLines.push(index)
                        return
                    }
                }
            }

            /// Team
            if (validatePartKey === 'team') {
                if (validateStructure.ignore && validateStructure.ignore.includes('team')) {
                    console.log("Ignored team")
                    return
                }

                if (validateTeam(part) === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": Team '" + part + "' is not supported (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            if (validatePartKey === 'oss_team') {
                if (validateStructure.ignore && validateStructure.ignore.includes('oss_team')) {
                    console.log("Ignored oss team")
                    return
                }

                if (validateAppTeam(part) === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": Team '" + part + "' is not supported (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            /// URL
            if (validatePartKey === 'url') {
                if (validateUrl(part) === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": URL is wrong (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            /// Performer ID
            if (validatePartKey === 'performer') {
                if (validatePerformer(part) === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": Performer ID is wrong (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            /// Required
            if (validatePartKey === 'required') {
                if ((part != null && part !== "") === false) {
                    messages.push({
                        index: index,
                        message: "Line "+ (index+1) + ": It is required (" + line + ")"
                    })
                    errorLines.push(index)
                    return
                }
            }

            if (validatePartKey === 'token') {
                // TODO: Add token validation for each platform
                return
            }

            if (validatePartKey === 'mlb_team') {
                // TODO: Add mlb team validation for mlb
                return
            }
        })
    })

    let validLines = lines.filter((line, index) => errorLines.includes(index) === false)
    if (lines.length === errorLines.length || validLines.length === 0 || errorLines.length > 0) {
        console.log(lines, errorLines, validLines)
        return {
            success: false,
            message: "No valid lines. (Error code: 902)",
            other: messages,
        }
    }

    return {
        success: true,
        message: "Scraping lines...",
        validLines: validLines,
        other: messages,
    }
}

function checkValidateStructure(structure) {
    let keys = Object.keys(validateStructure)
    if (keys.every(key => structure.hasOwnProperty(key)) === false) {
        return false
    }
}

function validateEmail(email) {
    let mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return mailPattern.test(email)
}

function validateUrl(urlString) {
    try {
        const newUrl = new URL(urlString)
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
    } catch (error) {
        return false
    }
}

function validateTeam(team) {
    try {
        let contains = Vue.prototype.$stateManagement.getters["teams/teams"].includes(team)
        if (process.env.NODE_ENV === 'development') {
            console.info(team, contains, "Returned true")
        }
        return contains
    } catch (error) {
        return false
    }
}

function validateAppTeam(team) {
    try {
        let contains = Vue.prototype.$stateManagement.getters["teams/appTeams"].includes(team)
        if (process.env.NODE_ENV === 'development') {
            console.info(team, "Returned", contains)
        }
        return contains
    } catch (error) {
        return false
    }
}

function validatePerformer(performerId) {
    let onlyDigits = /^\d+$/.test(performerId)
    let charLimit = performerId.toString().length == 4
    return charLimit && onlyDigits
}
