import {filterItems} from "@/base/helper/utils"

const componentMixin = {
    methods: {
        filterItems: filterItems,
    },
    computed: {
        computedClass: function () {
            // Size is for center content
            let classes = [this.size]
            if (this.preDefinedClasses) {
                this.preDefinedClasses.forEach(c => {
                    classes.push(c)
                })
            }
            return classes.concat(this.classObject)
        },
        computedDivClass: function () {
            let classes = []
            if (this.dense != null) {
                classes.push(this.dense ? ['my-1'] : ['my-2'])
            }
            return classes.concat(this.classDivObject)
        },
        computedRefName: function () {
            if (this.title != null) {
                return this.$root.slugify(this.title)
            } else {
                return "form"
            }
        },
        hasListener: function() {
            return (name) => (this.$listeners && this.$listeners[name])
        },
    }
}

export default componentMixin

