import Vue from "vue"
import VueRouter from "vue-router"
import {authRoutes} from "@/router/auth"
import {homeRoutes} from "@/router/home"
import {demoRoutes} from "@/router/demo"
import {getTitleByRoute} from "@/utils/routingHelper"

const { push } = VueRouter.prototype
const { isNavigationFailure, NavigationFailureType } = VueRouter

VueRouter.prototype.push = function (location) {
    return push.call(this, location).catch(error => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error)
        }
    })
}

Vue.use(VueRouter)

let routes = [].concat(authRoutes, homeRoutes)
if (process.env.NODE_ENV === 'development') {
    // TODO: Add if needed
    routes = routes.concat(demoRoutes)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: routes,
})

router.beforeEach((to, from, next) => {
    const loggedIn = Vue.prototype.$stateManagement.state.auth.isLoggedIn
    const isAdmin = !!Vue.prototype.$stateManagement.state.auth.account?.is_admin
    const authRequired = !!to.meta.auth
    const adminRequired = !!to.meta.admin

    document.title = getTitleByRoute(to)

    let name = to.name
    const page = Vue.prototype.$stateManagement.state.roles

    if (authRequired && loggedIn) {
        if (adminRequired && isAdmin === false) {
            next({ name: 'Welcome' })
        } else if (name === 'Welcome') {
            next()
        } else if (page.all.includes(name) === false && page.myPages.includes(name)) {
            next({ name: 'Welcome' })
        } else if (page.all.includes(name) && page.myPages.includes(name) === false) {
            next({ name: 'Welcome' })
        } else {
            next()
        }
    } else if (authRequired) {
        next({ name: 'login', query: { from: to.name }, params: { to } })
    } else {
        next()
    }
})

export default router
