<template>
  <div v-bind:class="computedDivClass">
    <v-textarea
      filled
      clearable
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      rows="5"
      :class="computedClass"
      :error-messages="error"
      :disabled="disabled"
      :rules="rules"
      :label="label"
      :hint="hint"
      v-model="localValue" />
  </div>
</template>

<script>
import ComponentMixin from "@/base/data/mixins/componentMixin"
import textBoxProps from "@/base/data/props/textBoxProps"

export default {
  name: "TextBox",
  mixins: [ComponentMixin],
  props: textBoxProps,
  data: () => ({
    localValue: null,
  }),
  created() {
    this.localValue = this.$props.value ?? ""
    this.$watch('localValue', (value) => {
      this.$emit('input', value)
    })
  },
  watch: {
    '$props.value': function(newValue) {
      this.localValue = newValue
    }
  }
}
</script>

<style scoped>

</style>
