import baseProps from "@/base/data/props/base"

const navigationTitleProps = {
    ...baseProps,
    text: {
        type: String,
        required: true,
        default: null,
    },
}

export default navigationTitleProps