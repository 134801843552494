import types from "@/data/types"

const rules = (type) => {
    switch (type) {
        case types.OSS_APP_INVENTORY:
        case types.OSS_APP:
            return {
                separator: ":",
                length: 3,
                items: ["oss_team", "email", "password"]
            }
        case types.OSS_API:
        case types.OSS_WEB:
        case types.OSS_API_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.OSS_CREDITS:
            return {
                separator: ":",
                length: 3,
                items: ["team", "email", "password"]
            }
        case types.TM_INVOICES:
            return {
                separator: ":",
                length: 3,
                items: ["team", "email", "password"]
            }
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return {
                separator: ":",
                length: 3,
                items: ["team", "email", "password"],
                ignore: ["team"]
            }
        case types.SG:
            return {
                separator: ":",
                length: 2,
                items: ["performer", "required"],
            }
        default:
            console.info("No ruleset for this type: ", type)
            return ""
    }
}

export default rules