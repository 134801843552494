import Vue from "vue"

const path = "qr"

export default {
    refresh(data) {
        return Vue.prototype.$http.post(`${path}/generate-async`,  data)
    },
    history(filterArray, isAdmin = false) {
        const options = { page: 1, items_per_page: 50, order_by: [], }
        const filter = filterArray ?? []
        const all = isAdmin
        return Vue.prototype.$http.post(`${path}/ticket-request/list`, { options, filter, all })
    },
    response(id, data = null) {
        return Vue.prototype.$http.get(`${path}/ticket-request/response/${id}`, { data })
    },
    credits(data) {
        return Vue.prototype.$http.post(`refresh/credits`, { data })
    },
    invoice(data) {
        return Vue.prototype.$http.post(`refresh/invoice`, { data })
    }
}
