import Demo from "@/base/demo/base/Demo"
import ModalDemo from "@/base/demo/ModalDemo"
import LoadingModalDemo from "@/base/demo/LoadingModalDemo"
import DatePickerDemo from "@/base/demo/DatePickerDemo"
import DateRangePickerDemo from "@/base/demo/DateRangePickerDemo"
import CheckboxDemo from "@/base/demo/CheckboxDemo"
import CopyableDemo from "@/base/demo/CopyableDemo"
import RadioButtonDemo from "@/base/demo/RadioButtonDemo"
import SelectBoxDemo from "@/base/demo/SelectBoxDemo"
import ConfirmModalDemo from "@/base/demo/ConfirmModalDemo"
import ActionContainersDemo from "@/base/demo/ActionContainersDemo"
import BottomSheetDemo from "@/base/demo/BottomSheetDemo"
import FormHeaderDemo from "@/base/demo/FormHeaderDemo"
import PanelDemo from "@/base/demo/PanelDemo"
import DataTableDemo from "@/base/demo/DataTableDemo.vue"

export const demoRoutes = [
    {
        path: '/demo',
        name: 'demo',
        component: Demo,
        meta: {
            auth: true,
            title: "Component Demos",
        },
    },
    {
        path: '/demo/datatable',
        name: 'datatable-demo',
        component: DataTableDemo,
        meta: {
            auth: true,
            title: 'DataTable Component Demo'
        },
    },
    {
        path: '/demo/modal',
        name: 'modal-demo',
        component: ModalDemo,
        meta: {
            auth: true,
            title: 'Modal Component Demo'
        },
    },
    {
        path: '/demo/loading-modal',
        name: 'loading-modal-demo',
        component: LoadingModalDemo,
        meta: {
            auth: true,
            title: 'Loading Modal Component Demo'
        },
    },
    {
        path: '/demo/confirm-modal',
        name: 'confirm-modal-demo',
        component: ConfirmModalDemo,
        meta: {
            auth: true,
            title: 'Confirm Modal Component Demo'
        },
    },
    {
        path: '/demo/datepicker',
        name: 'datepicker-demo',
        component: DatePickerDemo,
        meta: {
            auth: true,
            title: 'Date Picker Component Demo'
        },
    },
    {
        path: '/demo/date-range-picker',
        name: 'date-range-picker-demo',
        component: DateRangePickerDemo,
        meta: {
            auth: true,
            title: 'Date Range Picker Component Demo'
        },
    },
    {
        path: '/demo/checkbox',
        name: 'checkbox-demo',
        component: CheckboxDemo,
        meta: {
            auth: true,
            title: 'Checkbox Component Demo'
        },
    },
    {
        path: '/demo/copyable',
        name: 'copyable-demo',
        component: CopyableDemo,
        meta: {
            auth: true,
            title: 'Copyable Component Demo'
        },
    },
    {
        path: '/demo/radiobutton-demo',
        name: 'radiobutton-demo',
        component: RadioButtonDemo,
        meta: {
            auth: true,
            title: 'RadioButton Component Demo'
        },
    },
    {
        path: '/demo/selectbox-demo',
        name: 'selectbox-demo',
        component: SelectBoxDemo,
        meta: {
            auth: true,
            title: 'Selectbox Component Demo'
        },
    },
    {
        path: '/demo/action-container-demo',
        name: 'action-container-demo',
        component: ActionContainersDemo,
        meta: {
            auth: true,
            title: 'Action Container Component Demo'
        },
    },
    {
        path: '/demo/bottom-sheet-demo',
        name: 'bottom-sheet-demo',
        component: BottomSheetDemo,
        meta: {
            auth: true,
            title: 'Bottom Sheet Component Demo'
        },
    },
    {
        path: '/demo/form-header-demo',
        name: 'form-header-demo',
        component: FormHeaderDemo,
        meta: {
            auth: true,
            title: 'Form Header Component Demo'
        },
    },
    {
        path: '/demo/panel-demo',
        name: 'panel-demo',
        component: PanelDemo,
        meta: {
            auth: true,
            title: 'Panel Component Demo'
        },
    },
]