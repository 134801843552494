const baseProps = {
    classObject: {
        type: Array,
        required: false,
        default: null,
    },
    classDivObject: {
        type: Array,
        required: false,
        default: null,
    },
}

export default baseProps