<template>
  <CenterContent size="xs12 sm10 md4 lg4 xl4">
    <Panel :form="form" ref="panel" title="Welcome" description="Sign in your account" @onFormChange="onFormChange" :class-object="['px-5']">
      <Input outlined label="E-mail" :type="emailType" v-model="form.username" />
      <Input outlined label="Password" :type="passwordType" v-model="form.password" :min="5" :max="16" />
      <Alert :type="response.variant" :message="response.message" />
      <Button @onClick="onButtonClick" :loading="this.response.loading" title="Sign in" :class-object="['primary']" block text large></Button>
      <v-img
        contain
        class="ma-auto mt-15"
        :src="require('@/assets/obocos.png')"
        height="40px" />
    </Panel>
  </CenterContent>
</template>

<script>
import Vue from "vue"
import { mapGetters } from 'vuex'
import CenterContent from "@/base/components/container/CenterContent"
import Panel from "@/base/components/container/Panel"
import Input from "@/base/components/form/Input"
import Alert from "@/base/components/view/Alert"
import Button from "@/base/components/form/Button"
import pageMixin from "@/base/data/mixins/pageMixin"
import AccountServices from "@/services/EditorServices"

export default {
  name: "Login.vue",
  components: {Button, Alert, Input, Panel, CenterContent},
  mixins: [pageMixin],
  data: () => ({
    form: {
      username: null,
      password: null,
    },
  }),
  computed: {
    ...mapGetters({
      token: "auth/token"
    }),
  },
  methods: {
    onButtonClick() {
      this.$refs.panel.validate()

      if (this.isFormValid) {
        this.response.loading = true
        AccountServices.login(this.form.username, this.form.password).then(response => {
          this.response = response.networkResponse(true, true)
          this.onSaveUser(response.data)
        }).catch(error => {
          this.response = error.networkError?.(error, true)
        })
      }
    },
    onSaveUser(response) {
      if (response.code !== 100) return

      const account = {
        ...response.data.editor,
        id: response.data.roles?.id,
        editor_id: response.data.roles?.editor_id,
      }

      Promise.all([
        Vue.prototype.$stateManagement.dispatch('roles/setMyPages', { myPages: response.data.roles?.allowed_pages }),
        Vue.prototype.$stateManagement.dispatch('auth/login', { account: account, customer: response.data.customer, token: response.data.token })
      ]).catch(error => {
        console.log(error)
        this.handleCustomError(error.toString())
      })
    },
  },
  mounted() {
    Vue.prototype.$stateManagement.dispatch('roles/clearRoles')
    Vue.prototype.$stateManagement.dispatch('teams/logout')
    Vue.prototype.$stateManagement.dispatch('auth/logout')
  }
}
</script>
