import types from "@/data/types"

const textBoxLabels = (type) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_WEB:
        case types.OSS_APP:
            return "Please enter OSS account credentials (team:email:password)"
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.OSS_CREDITS:
            return "Please enter OSS account credentials (team:email:password)"
        case types.TM_PHONE:
            return "Please enter links: (https://downloadmyseats.com/orders/cd20b8a21398444daaf10a893510426f/index.html)"
        case types.PK_PASS:
            return ""
        case types.TM_INVOICES:
            return "Please enter account credentials (team:email:password)"
        case types.OSS_RESET:
            return "Please enter OSS account credential (team:email) Note: Support single account at once"
        case types.SG:
            return "Please enter SeatGeek.com account credentials: (performer_id:token)"
        case types.BALLPARK_APP:
            return "Please enter MLB BallPark account credentials: (team:email:password)"
        case types.BALLPARK_WEB:
            return "Please enter MLB BallPark account credentials: (team:email:password)"
        case types.BALLPARK_LINK:
            return "Please enter MLB account credentials: (email:password:team:target_email)"
        case types.BALLPARK_CREATE:
            return "Please enter MLB BallPark Account Detail: (email:password:firstname:lastname) Note: Support single account at once"
        case types.BALLPARK_VERIFY:
            return "Please enter MLB BallPark Account Detail: (account_email:account_pass:email_to_be_verified) Note: Support single account at once"
        default:
            return type
    }
}

export default textBoxLabels
