import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify);

const themesOptions = {
    themes: {
        light: {
            primary: "#1982C4",
            secondary: "#6A4C93",
            accent: "#AE86E7",
            error: "#FF595E",
            info: "#689cbe",
            success: '#079159',
            warning: '#FFCA3A',
            background: '#F2F5F8',
        },
    },
}

const options = {
    theme: themesOptions,
}

export default new Vuetify(options)
