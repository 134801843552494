import types from "@/data/types"

const includeBarcode = (type) => {
    switch (type) {
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
            return false
        default:
            return true
    }
}

export default includeBarcode