import types from "@/data/types"

const sources = (type) => {
    switch (type) {
        case types.OSS_API:
            return "api"
        case types.OSS_APP:
        case types.BALLPARK_APP:
            return "app"
        case types.OSS_WEB:
        case types.BALLPARK_WEB:
            return "web"
        case types.SG:
            return "api"
        default:
            return ""
    }
}

export default sources
