<template>
  <Card :form="form" has-form @onFormChange="onFormChange" ref="card">
    <v-row>
      <v-col cols="12">
        <Alert type="info" :message="custoMessage" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense
          label="Email"
          autoComplete="off"
          :type="emailType"
          v-model="form.dashboard_user" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense
          label="Password"
          v-model="password" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense
          label="First Name"
          :type="alphanumericType"
          v-model="form.first_name" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense
          label="Last Name"
          :type="alphanumericType"
          v-model="form.last_name" />
      </v-col>
      <v-col cols="12" class="py-0 my-0">
        <Select @input="onSelectChange" :value="selectboxModel" :items="items" multiple label="Select Pages" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Checkbox @input="(value) => { form.editor_status = value ? 'active' : 'passive' }" :value="form.editor_status === 'active'" title="Is Active" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Checkbox @input="(value) => { form.is_admin = value }" :value="form.is_admin" title="Editor Management" />
      </v-col>
      <v-col cols="12">
        <Alert :type="response.variant" :message="response.message" />
      </v-col>
      <v-col cols="12">
        <Button
          outlined block title="Save"
          @onClick="onSaveClick"
          :loading="response.loading"
          button-icon="mdi-content-save-outline" />
      </v-col>
    </v-row>
  </Card>
</template>

<script>
import Vue from "vue"
import Select from "@/base/components/form/Select"
import Checkbox from "@/base/components/form/Checkbox"
import CenterContent from "@/base/components/container/CenterContent"
import Card from "@/base/components/container/Card"
import PageMixin from "@/base/data/mixins/pageMixin"
import {menuItems} from "@/data/adminMenuItems"
import Button from "@/base/components/form/Button"
import Alert from "@/base/components/view/Alert"
import AccountServices from "@/services/EditorServices"
import Input from "@/base/components/form/Input"
import {mapGetters} from "vuex"
import router from "@/router"

export default {
  name: "AdminEditModal",
  components: {Alert, Button, Card, CenterContent, Checkbox, Select, Input},
  mixins: [PageMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      account: 'auth/account',
    }),
    selectboxModel() {
      return this.form?.roles?.allowed_pages ?? []
    },
  },
  data: () => ({
    form: null,
    password: null,
    items: [],
    custoMessage: ""
  }),
  methods: {
    createPageItems() {
      this.items = Object.keys(menuItems).map(key => {
        return {
          label: key,
          value: menuItems[key],
        }
      })
    },
    addCustomMessage() {
      if (this.form?.id === this.account?.editor_id) {
        this.custoMessage = "This is your account. After update, you need to login again."
      } else {
        this.custoMessage = null
      }
    },
    onSaveClick() {
      this.$refs.card.validate()

      if (!this.isFormValid) {
        return
      }

      if (!!this.password && this.password !== "") {
        this.form = {
          ...this.form,
          dashboard_pass: this.password
        }
      } else {
        this.form.dashboard_pass = ""
      }

      this.response.loading = true
      AccountServices.update(this.form).then(response => {
        this.response = response.networkResponse()
        if (this.form?.id === this.account?.editor_id) {
          Promise.all([
            Vue.prototype.$stateManagement.dispatch('auth/logout'),
            Vue.prototype.$stateManagement.dispatch('roles/clearRoles'),
            Vue.prototype.$stateManagement.dispatch('teams/logout')
          ]).then(() => {
            router.push({ name: 'login' })
          }).catch(() => {
            router.push({ name: 'login' })
          })
        }
      }).catch(error => {
        this.response = error.networkError(error)
      }).finally(() => {
        this.response.loading = false
      })
    },
    onSelectChange(select) {
      if (this.form.roles == null) {
        this.form.roles = {
          allowed_pages: [select]
        }
      } else {
        this.form.roles.allowed_pages = select
      }
    },
  },
  mounted() {
    this.createPageItems()
    this.addCustomMessage()
  },
  watch: {
    item: {
      immediate: true,
      handler(to) {
        this.form = to
        this.response.loading = false
        this.response.message = null
        this.addCustomMessage()
      },
    },
  },
}
</script>

<style scoped>

</style>
