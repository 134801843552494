<template>
  <Card has-form :form="form" @onFormChange="onFormChange" ref="card">
    <v-row>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense outlined
          label="Email"
          autoComplete="off"
          :type="emailType"
          v-model="form.dashboard_user" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense outlined
          label="Password"
          autoComplete="new-password"
          :type="passwordType"
          v-model="form.dashboard_password" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense outlined
          label="First Name"
          :type="alphanumericType"
          v-model="form.first_name" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Input
          dense outlined
          label="Last Name"
          :type="alphanumericType"
          v-model="form.last_name" />
      </v-col>
      <v-col cols="12" class="py-0 my-0">
        <Select
          multiple outlined
          @input="onSelectChange"
          :value="selectBoxModel"
          :items="items" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Checkbox :class-object="['my-4', 'pb-0']" title="Is Active" @input="(value) => { form.is_active = value }" :value="form.is_active" />
      </v-col>
      <v-col cols="6" class="py-0 my-0">
        <Checkbox :class-object="['my-4', 'pb-0']" title="Editor Management" @input="(value) => { form.is_admin = value }" :value="form.is_admin" />
      </v-col>
    </v-row>
    <v-row v-if="response.message">
      <v-col cols="12">
        <Alert :type="response.variant" :message="response.message" />
      </v-col>
    </v-row>
    <Button block title="Create Editor" outlined text @onClick="onCreateUserClick" large :loading="response.loading" />
  </Card>
</template>

<script>
import Alert from "@/base/components/view/Alert"
import Button from "@/base/components/form/Button"
import Card from "@/base/components/container/Card"
import CenterContent from "@/base/components/container/CenterContent"
import Checkbox from "@/base/components/form/Checkbox"
import Select from "@/base/components/form/Select"
import PageMixin from "@/base/data/mixins/pageMixin"
import Input from "@/base/components/form/Input"
import AccountServices from "@/services/EditorServices"
import {menuItems} from "@/data/adminMenuItems"

export default {
  name: "AdminCreateModal",
  components: {Input, Alert, Button, Card, CenterContent, Checkbox, Select},
  mixins: [PageMixin],
  data: () => ({
    form: {
      is_admin: false,
      is_active: true,
      platform_id: 1,
      customer_id: 1,
      first_name: null,
      last_name: null,
      dashboard_user: null,
      dashboard_password: null,
      editor_type: "qr",
      roles: {
        allowed_pages: [],
      },
    },
    items: [],
  }),
  computed: {
    selectBoxModel() {
      return this.form?.roles?.allowed_pages ?? []
    },
  },
  methods: {
    onCreateUserClick() {
      this.$refs.card.validate()

      if (!this.isFormValid) {
        return
      }

      this.response.loading = true
      AccountServices.create(this.form).then(response => {
        this.response = response.networkResponse()
      }).catch(error => {
        this.response = error.networkError(error)
      })
    },
    onSelectChange(select) {
      this.form.roles.allowed_pages = select
    },
    createPageItems() {
      this.items = Object.keys(menuItems).map(key => {
        return {
          label: key,
          value: menuItems[key],
        }
      })
    },
    resetForm() {
      this.form = {
        is_admin: false,
        is_active: true,
        platform_id: 1,
        customer_id: 1,
        first_name: null,
        last_name: null,
        dashboard_user: null,
        dashboard_password: null,
        editor_type: "qr",
        roles: {
          allowed_pages: [],
        },
      }
    },
  },
  mounted() {
    this.createPageItems()
  },
  watch: {
    '$attrs.show': function(newValue) {
      if (newValue === false) {
        this.resetForm()
      }
    },
  }
}
</script>
