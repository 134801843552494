import baseProps from "@/base/data/props/base"

const bottomHistoryProps = {
    ...baseProps,
    title: {
        type: String,
        required: true,
        default: "",
    },
    loading: {
        type: Boolean,
        required: true,
        default: true,
    },
    items: {
        type: Array,
        required: true,
        default: () => [],
    },
    headerValues: {
        type: Array,
        required: false,
        default: () => [],
    },
    show: {
        type: Boolean,
        required: true,
        default: false,
    },
    errorMessage: {
        type: String,
        required: false,
        default: null,
    },
}

export default bottomHistoryProps
