<template>
  <DemoBase size="xs4">
    <ConfirmModal
      title="Are you sure you want to delete lynettesims197@gmail.com?"
      description="If you click yes, this account will be deleted. This action cannot be undone."
      @onClickYes="dialog = false"
      @onClickNo="dialog = false"
      :show="dialog">

    </ConfirmModal>
  </DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase"
import ConfirmModal from "@/base/components/container/ConfirmModal"

export default {
  name: "ConfirmModalDemo",
  components: {ConfirmModal, DemoBase},
  data: () => ({
    dialog: true,
  }),
  methods: {

  }
}
</script>

<style scoped>

</style>