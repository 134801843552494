<template>
  <CenterContent size="xs12">
    <SP :class="['mb-3', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
    <v-card class="pa-2" outlined>
      <FileUpload
        @onFileRead="onFileRead"
        @onFileError="onFileError"
        accept=".zip"
        label="Upload PhonePass file (Supports only .zip that contains .pkpass files)" />
      <SP class="text-h6 font-weight-bold" v-if="isFileSelected">Preview ({{ files.length }} files)</SP>
      <SimpleDataTable
        v-if="isFileSelected"
        fixed-header
        :class-div-object="['mb-5']"
        :height="452"
        :custom-header="dataTableHeader"
        :items="files" />
      <SingleRow :col="12">
        <Alert :loading="alert.loading" :type="alert.variant" :message="alert.message" />
        <Alert :type="response.variant" :message="response.message">
          <Button v-if="response.variant !== 'error' " title="Download" outlined small block @onClick="downloadFile"></Button>
        </Alert>
      </SingleRow>
      <v-row>
        <v-col cols="6">
          <Button
            text outlined
            title="Generate PhonePass"
            button-icon="bx bx-barcode"
            @onClick="onClickGenerate"
            :class-object="['px-10']"
            :loading="response.loading"
            :disabled="!isFileSelected" />
        </v-col>
        <v-col cols="6">
          <Button
            title="Show My History"
            :disabled="response.loading"
            :class-object="['px-10', 'float-right']"
            @onClick="onHistoryClick"
            prepend-icon="bx bx-history"
            outlined />
        </v-col>
      </v-row>
    </v-card>
    <BottomSheet
      v-model="historySheet"
      class="mb-10"
      @onClose="onHistoryClose">
      <Alert type="error" :message="history.message" />
      <DataTable
        title="History"
        custom-loading-type="table-thead, table-tbody, table-tfoot"
        filterable
        group-by="status"
        :loading="history.loading"
        :custom-options="datatableOptions"
        :custom-actions="customActions"
        :custom-header-values="historyHeaderValues"
        :datatable-items="historyItems">
        <template v-slot:item.editor="{ item }">
          <TooltipLabel v-if="item.editor.dashboard_user" top :label="item.editor.dashboard_user">
            {{ [item.editor.first_name, item.editor.last_name].join(" ") }}
            <v-chip v-if="item.editor.is_admin" class="px-1 ma-0" label x-small>
              admin
            </v-chip>
          </TooltipLabel>
          <span v-else>API Test</span>
        </template>
        <template v-slot:item.account="{ item }">
          <span v-html="item.account"></span>
        </template>
        <template v-slot:item.time_range="{ item }">
          <span v-html="item.time_range"></span>
        </template>
        <template v-slot:item.files="{ item }">
          <TooltipLabel v-if="item.files && item.files?.length > 0" top :label="item.files.join('<br />')">
            {{ item.files.length }} files
          </TooltipLabel>
          <span v-else>No files</span>
        </template>
      </DataTable>
    </BottomSheet>
  </CenterContent>
</template>

<script>
import PageMixin from "@/base/data/mixins/pageMixin"
import socketMixin from "@/data/mixins/socketMixin"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"
import CenterContent from "@/base/components/container/CenterContent"
import FileUpload from "@/base/components/form/FileUpload"
import Button from "@/base/components/form/Button"
import SingleRow from "@/base/components/container/SingleRow"
import SimpleDataTable from "@/base/components/view/SimpleDataTable"
import Row from "@/base/components/container/Row"
import Alert from "@/base/components/view/Alert"
import SP from "@/base/components/container/SP"
import PKPassService from "@/services/PKPassService"
import GenerateService from "@/services/GenerateServices"
import BottomSheet from "@/base/components/container/BottomSheet"
import DataTable from "@/base/components/container/DataTable"
import TooltipLabel from "@/base/components/container/TooltipLabel"
import {formatBytes} from "@/base/helper/utils"
import RequestService from "@/services/RequestService"
import helper from "@/utils/viewHelper"
import moment from "moment"

export default {
  name: "pkpass",
  components: {Alert, BottomSheet, TooltipLabel, DataTable, Row, SimpleDataTable, SingleRow, FileUpload, CenterContent, Button, SP},
  mixins: [PageMixin, socketMixin, pageProjectMixin],
  computed: {
    customActions() {
      return [
        {
          title: "Download",
          click: this.onHistoryItemClick,
          isRendered: (item) => {
            return item.status === 'completed'
          }
        }
      ]
    },
  },
  data: () => ({
    isFileSelected: false,
    files: [],
    dataTableHeader: ["File Name", "Size"],
    errorMessage: null,
    fileName: null,
    file: null,
    fileBuffer: null,

    // History variables
    historySheet: false,
    historyItems: [],
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  }),
  methods: {
    onFileRead(header, files, fileName, file) {
      this.response.message = null
      this.response.loading = false
      this.alert.message = null
      this.alert.loading = false
      this.socketRequestId = null

      if (files.length === 0) {
        this.isFileSelected = false
      } else if (Object.keys(files.files).length > 0) {
        this.isFileSelected = true
        this.fileName = fileName
        this.file = file

        this.files = Object.values(files.files)
          .map(file => ({
            file_name: file.name,
            size: formatBytes(file._data.compressedSize),
          }))
          .filter(file => file.file_name.startsWith("__MACOSX") === false)
      }
    },
    onFileError(errorMessage, error) {
      this.errorMessage = errorMessage
    },

    // Button click
    onHistoryItemClick(item) {
      this.downloadFile(item.request_id)
    },
    onClickGenerate() {
      if (this.file !== null) {
        this.response.loading = true
        this.response.message = null

        this.alert.variant = 'info'
        this.alert.message = "Please stand by"
        this.alert.loading = true

        PKPassService.generateSocket(this.file, this.custom_data).then(response => {
          if (this.response.loading === false) { return }

          if (response.data.data.request_status === "requested") {
            this.socketRequestId = response.data.data.request_id
            this.alert.message =
                "We got your request. Generating PKPasses..."
                + "\nYou can close this page and check it later from history. ("
                + response.data.data.request_id + ")"
          } else {
            this.alert.variant = 'error'
            this.alert.message = response.data?.data?.response_message ?? "An error occurred. Please check your .zip file"
            this.alert.loading = false
            this.response.loading = false
          }
          this.scrollToTop()
        }).catch(error => {
          this.response = error.networkError(error)
          this.alert.message = null
          this.alert.loading = false
          this.response.loading = false
        })
      }
    },
    downloadFile(requestId) {
      this.alert.message = "Please stand by"

      let reqId = requestId ?? this.fileBuffer
      GenerateService.download(reqId, 'PK_PASS').then(response => {
        let reader = new FileReader()
        reader.onload = () => {
          let blob = new Blob([response.data], { type: 'application/zip' });
          let url = window['URL'].createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          let timestamp = moment().unix()
          a.download = `pkpass_${timestamp}.zip`
          a.click();
        }
        reader.readAsBinaryString(response.data)
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.message = null
      })
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
        this.alert.loading = false
      })
    },
    decorateHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: item.editor,
        file_name: item.imported_file_name,
        files: item.extra_data.file_list,
        size: formatBytes(item.request_data.file.size),
        status: item.request_status,
        result: this.getResult(item),
        request_time: this.printableDate(item.created_at),
        action: "",
        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    getResult(item) {
      if (item.request_status === "completed") {
        return `Generated ${item.extra_data.file_list.length} tickets`
      } else if (item.request_status === "failed") {
        return "Failed"
      }
      return ""
    },
    onHistoryClose() {
      this.historySheet = false
      this.historyItems = []
    },
    onSocketData(data) {
      console.log(data)
      this.alert.message = null
      this.alert.loading = false
      this.response.loading = false
      if (data.request_status === 'failed') {
        this.response.variant = 'error'
        this.response.message = this.getSocketError(data)
      } else {
        this.fileBuffer = data.request_id
        this.response.variant = 'success'
        if (data.response_message != null) {
          this.alert.message = `Info: ${data.response_message}`
        }
        this.response.message = `Successfully created.`
      }
    },
  },
  created() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
  },
}
</script>
