<template>
<CenterContent size="xs12">
  <SP :class="['mb-3', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
  <v-card class="pa-2" outlined>
    <TextBox
      :value="accounts"
      :label="viewHelpers.textBoxLabel"
      :hint="viewHelpers.textBoxHint"
      :error="textBoxError"
      @input="onAccountChange" />
    <Alert outlined :type="response.variant" border="left" :message="response.message" />
    <Alert outlined type="error" border="left" :message="warningError" />
    <v-row>
      <v-col cols="6">
        <Button
          title="Reset Password(s)"
          :loading="response.loading"
          :class-object="['px-10']"
          @onClick="onButtonClick"
          prepend-icon="bx bx-refresh"
          outlined />
      </v-col>
      <v-col cols="6">
        <Button
          title="Show My History"
          :loading="response.loading"
          :class-object="['px-10', 'float-right']"
          @onClick="onHistoryClick"
          prepend-icon="bx bx-history"
          outlined />
      </v-col>
    </v-row>
  </v-card>
  <BottomSheet
    v-model="historySheet"
    class="mb-10"
    @onClose="onHistoryClose">
    <Alert type="error" :message="history.message" />
    <DataTable
      title="History"
      custom-loading-type="table-thead, table-tbody, table-tfoot"
      filterable
      group-by="status"
      :default-slots="false"
      :loading="history.loading"
      :custom-options="datatableOptions"
      :custom-header-values="historyHeaderValues"
      :datatable-items="historyItems">
      <template v-slot:item.editor="{ item }">
        <TooltipLabel v-if="item.editor.dashboard_user" top :label="item.editor.dashboard_user">
          {{ [item.editor.first_name, item.editor.last_name].join(" ") }}
          <v-chip v-if="item.editor.is_admin" class="px-1 ma-0" label x-small>
            admin
          </v-chip>
        </TooltipLabel>
        <span v-else>API Test</span>
      </template>

      <template v-slot:item.request="{ item }">
        <TooltipLabel top :label="item.request.account_team + ':' + item.request.account_user">
          {{ item.request.account_user }}
        </TooltipLabel>
      </template>

      <template v-slot:item.status="{ item }">
        <TooltipLabel top :label="item.request_id">
          <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
          <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else>{{ item.status }}</span>
        </TooltipLabel>
      </template>
    </DataTable>
  </BottomSheet>
</CenterContent>
</template>

<script>
import helper from "@/utils/viewHelper"
import CenterContent from "@/base/components/container/CenterContent"
import TextBox from "@/base/components/form/TextBox"
import PageMixin from "@/base/data/mixins/pageMixin"
import HistoryAction from "@/view/components/HistoryAction"
import Button from "@/base/components/form/Button"
import Alert from "@/base/components/view/Alert"
import AccountService from "@/services/AccountService"
import BottomSheet from "@/base/components/container/BottomSheet"
import DataTable from "@/base/components/container/DataTable"
import TooltipLabel from "@/base/components/container/TooltipLabel"
import Sp from "@/base/components/container/SP"
import {baseValidation} from "@/data/view/validate"
import RequestService from "@/services/RequestService"
import SP from "@/base/components/container/SP.vue"

export default {
  name: "ossResetPassword",
  components: {SP, Sp, Alert, DataTable, CenterContent, TextBox, HistoryAction, Button, BottomSheet, TooltipLabel},
  mixins: [PageMixin],
  data: () => ({
    accounts: "",

    // TextBox variables
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],
    rules: {
      separator: ":",
      length: 2,
      items: ["team", "email"],
    },

    // History variables
    historyItems: [],
    historySheet: false,
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  }),
  methods: {
    onAccountChange(accounts) {
      this.accounts = accounts
    },

    // Button Clicks
    onButtonClick() {
      this.warningError = null
      this.textBoxError = ""
      this.textBoxErrorLines = []
      this.response.message = null

      let validateResponse = baseValidation(this.accounts, this.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
        return
      }

      let emailPassPair = validateResponse.validLines[0].split(":")

      let model = {
        account_team: emailPassPair[0],
        account_user: emailPassPair[1],
        custom_data: this.custom_data,
      }

      this.response.loading = true
      AccountService.reset(model).then(response => {
        this.response = response.networkResponse()
        if (response.data.data.request_status === 'failed') {
          this.response.message = response.data.data.api_response.response
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      })
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true
      this.response.message = null

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      })
    },
    decorateHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: item.editor,
        request: item.request_data,
        result: item.decorated_response.response,
        status: item.request_status,
        request_time: this.printableDate(item.created_at),
        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    onHistoryClose() {
      this.history.message = null
      this.historySheet = false
      this.historyItems = []
    },
    checkParams() {
      if (this.$route.query.account) {
        this.accounts = this.$route.query.account
      }
    },
  },
  mounted() {
    this.checkParams()
  },
  created() {
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel
  }
}
</script>
