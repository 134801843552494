import baseProps from "@/base/data/props/base"

const modalProps = {
    ...baseProps,
    width: {
        type: Number,
        required: false,
        default: 500,
    },
    show: {
        type: Boolean,
        required: false,
        default: false,
    },
    overlay: {
        type: Boolean,
        required: false,
        default: true,
    },
    cancelable: {
        type: Boolean,
        required: false,
        default: true,
    },
    hasForm: {
        type: Boolean,
        required: false,
        default: false,
    },
    title: {
        type: String,
        required: false,
        default: null,
    },
    // Confirm Modal
    description: {
        type: String,
        required: false,
        default: "Please stand by",
    }
}

export default modalProps
