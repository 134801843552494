<template>
<DemoBase size="xs5">
  <FormHeader title="Example" description="Description" class="pa-10 text-center" />
  <v-divider></v-divider>
  <FormHeader icon="platform/qrcode.png" title="With Icon" description="Description" class="pa-10 text-center" />
</DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase"
import FormHeader from "@/base/components/container/FormHeader"

export default {
  name: "FomHeaderDemo",
  components: {FormHeader, DemoBase}
}
</script>

<style scoped>

</style>