import Vue from "vue";

const appMixin = {
    methods: {
        socketChannel: () => {
            const channel = 'qr-request'
            const editorId = Vue.prototype.$stateManagement.state.auth?.account?.editor_id
            return [channel, editorId].join("-")
        }
    },
    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        }
    },
}
export default appMixin