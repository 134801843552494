import types from "@/data/types"

const includeOption = (type) => {
    switch (type) {
        case types.OSS_API:
            return true
        default:
            return false
    }
}

export default includeOption