<template>
  <div class="cursor" v-bind:class="classDivObject">
    <slot>
      <Sp span :class-object="['text-h5', 'font-weight-bold', 'ml-5', 'float-left']" @onClick="onTitleClick">{{ text }}</Sp>
    </slot>
  </div>
</template>

<script>
import Sp from "@/base/components/container/SP"
import NavigationTitleProps from "@/data/props/navigationTitleProps"
import routeMixin from "@/base/data/mixins/routeMixin";

export default {
  name: "NavigationTitle",
  components: {Sp},
  mixins: [routeMixin],
  props: NavigationTitleProps,
}
</script>
