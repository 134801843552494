import { slugify } from './utils'
import {getBaseApiURL, getFormattedDate, isValidDate} from "@/base/helper/date"

const Application = Object.freeze({
    name: 'V4',
    version: '1.0.0',
    helpers: {
        slug: slugify,
        isDate: isValidDate,
        formatDate: getFormattedDate,
        getBaseUrl: getBaseApiURL,
    }
})

export default Application
