import Vue from 'vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'

let options = {
    colors: {
        primary:'#5b3cc4',
        success:'rgb(13,121,60)',
        danger:'rgb(242, 19, 93)',
        warning:'rgb(255, 130, 0)',
        dark:'rgb(36, 33, 69)'
    }
}

Vue.use(Vuesax, options)

export default () => {}

