import baseProps from "@/base/data/props/base"

const centerComponentsProps = {
    ...baseProps,
    size: {
        type: String,
        required: false,
        default: "xs6",
    },
}

export default centerComponentsProps