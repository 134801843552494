import types from "@/data/types"

const ticketHeaders = (type) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_APP:
        case types.OSS_WEB:
            return [
                'team', 'account_user', 'event_id', 'event',
                'date', 'time', 'section', 'row', 'seat', 'barcode', 'additional', 'ticket_id',
            ]
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
            return [
                'team', 'account_user', 'event_id', 'event',
                'date', 'time', 'section', 'row', 'seat',
            ]
        case types.TM_PHONE:
            return [
                'team', 'event', 'section', 'row', 'seat', 'barcode', 'venue', 'date', 'time'
            ]
        case types.SG:
            return [
                'account_user', 'date', 'time', 'timestamp', 'section', 'row', 'seat',
                'event_id', 'team', 'event', 'year', 'venue', 'barcode', 'info'
            ]
        case types.OSS_CREDITS:
            return [
                'account_user', 'credit_amount', 'resale_credit'
            ]
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return [
                'account_user', 'account_number', 'date', 'time', 'section', 'row', 'seat',
                'event_id', 'teams', 'alternative', 'year', 'venue', 'barcode', 'ticket_id'
            ]
        default:
            console.info("No ticket header for the type: ", type)
    }
}

export default ticketHeaders
