import types from "@/data/types"

const accountModalStructure = (type, validAccounts) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_APP:
        case types.OSS_WEB:
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.OSS_CREDITS:
        case types.TM_INVOICES:
        case types.BALLPARK_APP:
        case types.BALLPARK_WEB:
            return validAccounts.map(account => {
                const [team, email, password] = account.split(":")
                return {
                    account_user: email,
                    account_password: password,
                    account_team: team,
                }
            })
        case types.SG:
            return validAccounts.map(account => {
                const [performer, token] = account.split(":")
                return {
                    account_performer: performer,
                    account_token: token,
                }
            })
        default:
            console.info("No match account struct for the type: ", type)
            return {}
    }
}

export default accountModalStructure
