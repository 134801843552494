<template>
<div :class="computedClass">
  <div v-if="icon">
    <v-row>
      <v-col cols="4">
        <v-img :src="require('@/assets/' + icon)" contain height="80px" />
      </v-col>
      <v-col cols="7">
        <sp class="text-h4">{{ title }}</sp>
        <sp v-if="description" class="grey--text font-weight-light mt-2">{{ description }}</sp>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <sp class="text-h4">{{ title }}</sp>
    <sp v-if="description" class="grey--text font-weight-light mt-2">{{ description }}</sp>
  </div>
</div>
</template>

<script>
import componentMixin from "@/base/data/mixins/componentMixin"
import formHeaderProps from "@/base/data/props/formHeader"
import sp from "@/base/components/container/SP"

export default {
  name: "FormHeader",
  props: formHeaderProps,
  components: { sp },
  mixins: [componentMixin],
}
</script>