<template>
  <DemoBase size="xs4">
    Date Range Picker
    <DateRangePicker></DateRangePicker>

    Start & End date
    <DateRangePicker
      @onPicked="onPicker"
      starting-date="2022-10-05"
      ending-date="2022-11-05"
    ></DateRangePicker>
  </DemoBase>
</template>

<script>

import DemoBase from "@/base/demo/base/DemoBase"
import DateRangePicker from "@/base/components/form/DateRangePicker"

export default {
  name: "DateRangePickerDemo",
  components: {DateRangePicker, DemoBase},
  methods: {
    onPicker(from, date) {
    }
  }
}
</script>

<style scoped>

</style>
