<template>
  <DemoBase>
    <LoadingModal :show="response.loading"></LoadingModal>
  </DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase";
import LoadingModal from "@/base/components/container/LoadingModal"
import PageMixin from "@/base/data/mixins/pageMixin"

export default {
  name: "LoadingModalDemo",
  components: {LoadingModal, DemoBase},
  mixins: [PageMixin],
  mounted() {
    this.loadingTest()
  }
}
</script>

<style scoped>

</style>