import baseProps from "@/base/data/props/base"

const historyActionProps = {
    ...baseProps,
    hasTestAccounts: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasHistory: {
        type: Boolean,
        required: false,
        default: true,
    },
    onHistoryClick: {
        type: Function,
        required: false,
        default: null,
    },
    historyErrorMessage: {
        type: String,
        required: false,
        default: null,
    },
    onTestClick: {
        type: Function,
        required: false,
        default: null,
    },
    items: {
        type: Array,
        required: false,
        default: () => [],
    },
    headerValues: {
        type: Array,
        required: false,
        default: () => [],
    },
    onBottomSheetClose: {
        type: Function,
        required: false,
        default: () => {},
    },
    onActionClick: {
        type: Function,
        required: false,
        default: () => {},
    },
}

export default historyActionProps
