import types from "@/data/types"

const enableCheck = (type) => {
    switch (type) {
        case types.BALLPARK_WEB:
            return false
        default:
            return true
    }
}

const printCheck = (type) => {
    switch (type) {
        case types.OSS_APP:
        case types.OSS_WEB:
            return true
        default:
            return false
    }
}

const downloadCSVCheck = (type) => {
    switch (type) {
        case types.OSS_API:
        case types.OSS_WEB:
        case types.OSS_APP:
        case types.BALLPARK_WEB:
        case types.BALLPARK_APP:
        case types.OSS_API_INVENTORY:
        case types.OSS_APP_INVENTORY:
        case types.OSS_WEB_INVENTORY:
        case types.SG:
            return true
        default:
            return false
    }
}

export { enableCheck, printCheck, downloadCSVCheck }