export default {
    namespaced: true,
    state: {
        count: 0,
    },
    getters: {
        count: function (state) {
            return state.count
        },
    },
    mutations: {
        SET_COUNT(state) {
            state.count++
        }
    },
    actions: {
        increment(context) {
            context.commit('SET_COUNT')
        }
    }
}