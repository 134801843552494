import Vue from "vue"

const path = "qr"

export default {
    generate(data) {
        return Vue.prototype.$http.post(`${path}/generate`, { data })
    },
    generateWithQueue(data) {
        return Vue.prototype.$http.post(`${path}/generate-with-queue`, { data })
    },
    generatePDF(data) {
        return Vue.prototype.$http.post(`${path}/web/print-ticket`, { data })
    },
    generateCSV(data) {
        return Vue.prototype.$http.post(`${path}/to-csv`, { data })
    },
    download(request_id, request_type, callback) {
        const downloadProgress = function (progressEvent) {
            const total = parseFloat(progressEvent.total)
            const current = progressEvent.loaded
            let percentCompleted = Math.floor(current / total * 100)
            callback?.(percentCompleted)
        }
        return Vue.prototype.$http.get(`${path}/download`, {
            params: { request_id, request_type },
            accept: 'application/zip',
            responseType: 'blob',
            onDownloadProgress: downloadProgress,
        })
    },
    history(filterObject = [], isAdmin = false) {
        const options = { page: 1, items_per_page: 50, order_by: [], }
        const filter = filterObject
        const all = isAdmin

        return Vue.prototype.$http.post(`${path}/request/list`, { options, filter, all })
    }
}
