import baseProps from "@/base/data/props/base"

const datepickerProps = {
    ...baseProps,
    value: {
        type: String,
        required: false,
        default: null,
    },
    startDate: {
        type: Date,
        required: false,
        default: () => new Date()
    },
    minDate: {
        type: String,
        required: false,
    },
    maxDate: {
        type: String,
        required: false,
    },
    placeholder: {
        type: String,
        required: false,
        default: null,
    },
    width: {
        type: Number,
        required: false,
        default: 300,
    },
    allowPast: {
        type: Boolean,
        required: false,
        default: false,
    },
    icon: {
        type: String,
        required: false,
        default: "mdi-calendar",
    },
    noIcon: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default datepickerProps

