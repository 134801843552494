import moment from "moment"

export const defaultFormat = "YYYY-MM-DD"
export const printableFormat = "MMM DD, YYYY hh:mm A"
export const usDateFormat = "ddd, MM/DD"

export const isValidDate = (date) => moment(new Date(date)).isValid()
export const getTodayDate = () => moment().toISOString()
export const getTodayFormattedDate = () => moment().format(defaultFormat)
export const getFormattedDate = (date) => moment(new Date(date)).format(defaultFormat)
export const getPrintableData = (date) => moment(new Date(date)).format(printableFormat)
export const getBaseApiURL = () => process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION
export const getDateFromUnix = (timestamp) => moment.unix(timestamp).format(defaultFormat)
export const fromNow = (date) => moment(date).fromNow()

// - Operations
export const getDateWithMinusDays = (days) => moment().subtract(days, 'days').format(defaultFormat)
export const getDateWithMinusMonths = (months) => moment().subtract(months, 'months').format(defaultFormat)
// + Operations
export const getDateWithPlusDays = (days, format = true) => {
    if (format) {
        return moment().add(days, 'days').format(defaultFormat)
    } else {
        return moment().add(days, 'days')
    }
}
export const getDateWithPlusMonths = (months, format = true) => {
    if (format) {
        return moment().add(months, 'months').format(defaultFormat)
    } else {
        return moment().add(months, 'months')
    }
}

// Add days to given date
export const addDays = (date, days) => moment(date).add(days, 'days').format(defaultFormat)
