<template>
<DemoBase size="xs4">
  Default
  <Radio :buttons="['Allora', 'Mallora', 'Kallora']"></Radio>

  Custom color
  <Radio :buttons="['Allora', 'Mallora', 'Kallora']" color="secondary"></Radio>

  Horizontal
  <Radio horizontal :buttons="['Allora', 'Mallora', 'Kallora']" color="secondary"></Radio>
</DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase"
import Radio from "@/base/components/form/Radio"

export default {
  name: "RadioButtonDemo",
  components: {Radio, DemoBase}
}
</script>

<style scoped>

</style>