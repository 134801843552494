<template>
  <DemoBase size="xs10">
    Basic
    <DataTable
      header-group-by
      :datatable-items="items" />
    <v-divider class="my-5" />

  </DemoBase>
</template>

<script>
import SP from "@/base/components/container/SP.vue"
import DemoBase from "@/base/demo/base/DemoBase.vue"
import Checkbox from "@/base/components/form/Checkbox.vue"
import DataTable from "@/base/components/container/DataTable.vue"

export default {
  name: "DataTableDemo",
  components: {DataTable, Checkbox, DemoBase, SP},
  data: () => ({
    items: [
      {"event": "NY Clippers Match Day 1", "data": "A Class", "venue": "Home", "section": 15, "row": 20, "seat": 20, },
      {"event": "NY Clippers Match Day 1", "data": "B Class", "venue": "Home", "section": 15, "row": 20, "seat": 22, },
      {"event": "NY Clippers Match Day 2", "data": "A Class", "venue": "Away", "section": 15, "row": 20, "seat": 10, },
      {"event": "NY Clippers Match Day 2", "data": "C Class", "venue": "Away", "section": 15, "row": 21, "seat": 10, },
      {"event": "NY Clippers Match Day 3", "data": "D Class", "venue": "Home", "section": 15, "row": 20, "seat": 20, },
      {"event": "NY Clippers Match Day 3", "data": "E Class", "venue": "Home", "section": 15, "row": 20, "seat": 21, },
      {"event": "NY Clippers Match Day 3", "data": "F Class", "venue": "Home", "section": 15, "row": 20, "seat": 22, },
    ],
  })
}
</script>
