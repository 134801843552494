
const socketMixin = {
    data: () => ({
        socketRequestId: null,
        isGenerate: false,
    }),
    methods: {
        getSocketError(data) {
            let message = data.response_message ?? ""
            let errors = data.response_errors?.map(error => `${error.account_user || ""} -> ${error._error_message}`) ?? []

            return [message, errors.join("<br/>")].join("<br/>")
        },
        handleSocketResponse(data, customMessage = "") {
            if (data.request_status === 'requested') {
                console.log(data.request_id)
                this.socketRequestId = data.request_id
                this.alert.message =
                    "We got your request. " +
                    customMessage +
                    "<br />You can close this page and check it later from history. (" + data.request_id + ")"
            } else {
                console.log("Could not handle errore for now")
            }
        },
        handleDownloadResponse(response) {
            this.isGenerate = false
            if (response.file_list.length > 0) {
                this.fileBuffer = response.request_id
                this.fileName = response.file_name
                this.successMessage = "QR Generated Successfully"
                if (this.generateOptions?.cloakify) {
                    this.successMessage += " including Cloakify format"
                }
            }
            if (response.not_created_list.length > 0) {
                this.response.variant = 'error'
                this.response.message = 'There are some errors: <br />'
                this.response.message += response.not_created_list.map(item => `${item.account_user ?? ""} -> ${item.section}.${item.row}.${item.seat}: ${item.error_message}`).join("<br />")
            }

            this.alert.message = null
            this.alert.loading = false
        },
    },
    mounted() {
        if (this.hasSocket) {
            const channel = this.socketChannel()
            console.log(`[${this.custom_data.title}] -> subscribing: `, channel)
            this.sockets.subscribe(channel, (data) => {
                console.log(`[${this.custom_data.title}] -> Got socket data:`, data)
                if (this.socketRequestId && this.socketRequestId === data.request_id) {
                    console.log("Request id matched, passing")

                    // If request generating some tickets, just handle it
                    if (this.isGenerate) {
                        this.handleDownloadResponse(data)
                    } else {
                        this.onSocketData?.(data)
                    }
                } else {
                    console.log("Not for this page")
                }
            })
        } else {
            console.log("Socket not supported")
        }
    },
    beforeDestroy() {
        if (this.hasSocket) {
            const channel = this.socketChannel()
            this.sockets.unsubscribe(channel)
            console.log(`[${this.custom_data.title}] -> unsubscribing: `, channel)
        }
    },
}

export default socketMixin