import Home from "@/view/home/Home"
import {genericRoutes} from "@/router/generic"
import Spreadsheet from "@/view/csv/Spreadsheet"
import Types from "@/data/types"
import Welcome from "@/view/home/Welcome"

// TicketMaster
import refresh from "@/view/scrape/refresh.vue"
import tmPasses from "@/view/pages/tmPhonePassLink.vue"
import invoice from "@/view/scrape/invoice.vue"
import ossResetPassword from "@/view/pages/ossResetPassword"
import pkpass from "@/view/pages/tmPhonePassPKPassPDF.vue"
import credits from "@/view/scrape/credits.vue"

// MLB Ballpark
import ballparkCreateAccount from "@/view/pages/ballparkCreateAccount"
import ballparkVerifyAccount from "@/view/pages/ballparkVerifyAccount"

// Admin
import AdminHome from "@/view/admin/AdminHome"

function getGenericRoutes() {
    if (process.env.NODE_ENV === 'development') {
        return { path: '' }
    }
    return genericRoutes
}

export const homeRoutes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            auth: true,
            title: 'Home',
        },
        children: [
            {
                path: "/",
                name: "Welcome",
                component: Welcome,
                meta: {
                    auth: true,
                }
            },
            {
                path: '/:platform/oss-api',
                name: 'oss-api',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.OSS_API,
                    title: "OSS API",
                }
            },
            {
                path: '/:platform/am-app',
                name: 'oss-app',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.OSS_APP,
                    title: "AM APP",
                }
            },
            {
                path: '/:platform/am-web',
                name: 'oss-web',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.OSS_WEB,
                    title: "AM WEB",
                }
            },

            // {
            //     path: '/ticketmaster/oss-api-inventory',
            //     name: 'oss-api-inventory',
            //     component: refresh,
            //     meta: {
            //         auth: true,
            //         type: Types.OSS_API_INVENTORY,
            //         title: 'OSS API Inventory',
            //     },
            // },
            // {
            //     path: '/ticketmaster/oss-app-inventory',
            //     name: 'oss-app-inventory',
            //     component: refresh,
            //     meta: {
            //         auth: true,
            //         type: Types.OSS_APP_INVENTORY,
            //         title: 'OSS APP Inventory',
            //     },
            // },
            // {
            //     path: '/ticketmaster/oss-web-inventory',
            //     name: 'oss-web-inventory',
            //     component: refresh,
            //     meta: {
            //         auth: true,
            //         type: Types.OSS_WEB_INVENTORY,
            //         title: 'OSS WEB Inventory',
            //     },
            // },

            {
                path: '/ticketmaster/oss-web-invoices',
                name: 'oss-web-invoices',
                component: invoice,
                meta: {
                    auth: true,
                    type: Types.TM_INVOICES,
                    title: 'Pull AM Invoices',
                },
            },

            {
                path: '/ticketmaster/oss-pass-reset',
                name: 'oss-pass-reset',
                component: ossResetPassword,
                meta: {
                    auth: true,
                    type: Types.OSS_RESET,
                    title: 'OSS Reset Password',
                },
            },

            {
                path: '/:platform/spreadsheet-to-qr',
                name: 'spreadsheet-to-qr',
                component: Spreadsheet,
                meta: {
                    auth: true,
                    type: Types.XLS_TO_QR,
                    title: 'Spreadsheet to QR',
                },
            },

            {
                path: '/ticketmaster/pkpass-upload',
                name: 'pkpass-upload',
                component: pkpass,
                meta: {
                    auth: true,
                    type: Types.PK_PASS,
                    title: 'iPhone PKPass to PDF-QR',
                },
            },
            {
                path: '/ticketmaster/pkpass-link',
                name: 'pkpass-link',
                component: tmPasses,
                meta: {
                    auth: true,
                    type: Types.TM_PHONE,
                    title: 'Wallet Links to QR'
                },
            },
            {
                path: '/ticketmaster/oss-credits',
                name: 'oss-credits',
                component: credits,
                meta: {
                    auth: true,
                    type: Types.OSS_CREDITS,
                    title: "AM Credits in Account",
                }
            },


            // BALLPARK
            {
                path: '/:platform/mlb-app-to-qr',
                name: 'mlb-app-to-qr',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.BALLPARK_APP,
                    title: 'Ballpark to QR',
                },
            },
            {
                path: '/ballpark/mlb-web-to-qr',
                name: 'mlb-web-to-qr',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.BALLPARK_WEB,
                    title: 'MLB WEB',
                },
            },
            {
                path: '/ballpark/mlb-app-verify',
                name: 'mlb-app-verify',
                component: ballparkVerifyAccount,
                meta: {
                    auth: true,
                    type: Types.BALLPARK_VERIFY,
                    title: 'Ballpark Verify Email',
                },
            },
            {
                path: '/ballpark/mlb-create-account',
                name: 'mlb-create-account',
                component: ballparkCreateAccount,
                meta: {
                    auth: true,
                    type: Types.BALLPARK_CREATE,
                    title: 'MLB Create Account',
                },
            },


            /// SEATGEEK
            {
                path: '/:platform/seatgeek-api',
                name: 'seatgeek-api',
                component: refresh,
                meta: {
                    auth: true,
                    type: Types.SG,
                    title: 'SeatGeek API',
                },
            },
            {
                path: '/:platform/sg-spreadsheet-to-qr',
                name: 'sg-spreadsheet-to-qr',
                component: Spreadsheet,
                meta: {
                    auth: true,
                    type: Types.XLS_TO_QR_SG,
                    title: 'SG Spreadsheet to QR',
                },
            },
        ],
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminHome,
        meta: {
            auth: true,
            admin: true,
            title: 'Admin',
        },
    },
    // These routes should be end of the array
    getGenericRoutes()
]
