import * as XLSX from 'xlsx/xlsx.mjs'
import JSZip from "jszip"

const listingFiles = ["csv", "xlsx", "xls"]

const fileReader = (file, callback) => {
    if (listingFiles.includes(file.name.split(".").pop())) {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file)
        fileReader.onload = (e) => {
            onXLSXRead(e, callback)
        }
    } else {
        onZipRead(file, callback)
    }
}

function onXLSXRead(event, callback) {
    const binaryString = event.target.result

    try {
        let data = getData(binaryString).map(item => toLowerCaseKeys(item))
        const result = {
            "header": getHeader?.(binaryString),
            "data": data,
        }
        callback(result, null)
    } catch(error) {
        callback(null, error)
    }
}

function onZipRead(file, callback) {
    JSZip.loadAsync(file).then(files => {
        const result = {
            data: files
        }

        callback(result, null)
    }).catch(error => {
        callback(null, error)
    })
}

function getHeader(bstr) {
    const wb = XLSX.read(bstr, {  type: 'binary' })
    let arr = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
    return (arr.length > 0) ? Object.keys(arr[0]) : []
}

function getData(bstr) {
    const wb = XLSX.read(bstr, {  type: 'binary' })
    let arr = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: false })
    return (arr.length > 0) ? arr : []
}

function toLowerCaseKeys(object) {
    return Object.keys(object).reduce(function (newObj, key) {
        let val = object[key];
        let newVal = (typeof val === 'object') ? toLowerCaseKeys(val) : val;
        newObj[key.toLowerCase()] = newVal;
        return newObj;
    }, {} );
}

// Base64 to Buffer
const base64ToBuffer = (content) => {
    if (content.split(',').length !== 2) { return }

    let base64 = content.split(",")[1]
    let base64content = window.atob(base64)
    let buffer = new ArrayBuffer(base64content.length)
    let view = new Uint8Array(buffer)
    for (let i = 0; i < content.length; i++) {
        view[i] = base64content.charAt(i)
    }
    return buffer
}

export default fileReader
