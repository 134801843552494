import baseProps from "@/base/data/props/base"

const panelProps = {
    ...baseProps,
    title: {
        type: String,
        required: true,
        default: null,
    },
    description: {
        type: String,
        required: false,
        default: null,
    },
    errorMessage: {
        type: String,
        required: false,
        default: null,
    },
    successMessage: {
        type: String,
        required: false,
        default: null,
    },
    hasForm: {
        type: Boolean,
        required: false,
        default: true,
    },
    form: {
        type: Object,
        required: false,
        default: () => {},
    },
    icon: {
        type: String,
        required: false,
        default: 'platform/qrcode.png',
    },
}

export default panelProps