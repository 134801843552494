import hints from "@/data/view/hint"
import labels from "@/data/view/label"
import models from "@/data/view/models"
import ticketHeaders from "@/data/view/ticketHeaders"
import ticketParser from "@/data/view/ticketParser"
import tbdCheckbox from "@/data/view/tbd"
import sources from "@/data/view/sources"
import filters from "@/data/view/filters"
import rules from "@/data/view/rules"
import descriptions from "@/data/view/descriptions"
import includeBarcode from "@/data/view/includeBarcode"
import requestTypes from "@/data/view/requestTypes"
import { enableCheck, printCheck, downloadCSVCheck } from "@/data/view/enableCheck"
import ticketGenerateHelper from "@/data/view/ticketGenerateHelper"
import prepareCSVItems from "@/data/view/prepareCSVItems"
import tableSort from "@/data/view/tableSort"
import ticketGenerateValues from "@/data/view/ticketGenerateValues"
import includeOption from "@/data/view/includeOption"

const viewHelpers = (type) => {
    return {
        textBoxHint: hints(type),
        textBoxLabel: labels(type),
        textBoxModel: models(type),
        rules: rules(type),

        ticketHeader: ticketHeaders(type),
        ticketGenerateHelper: ticketGenerateHelper(type),
        ticketGenerateValues: ticketGenerateValues(type),
        ticketSort: tableSort(type),

        hasTBD: tbdCheckbox(type),
        source: sources(type),
        filter: filters(type),
        enabled: enableCheck(type),
        description: descriptions(type),
        hasPrint: printCheck(type),
        hasCSV: downloadCSVCheck(type),

        includeOption: includeOption(type),
        includeBarcode: includeBarcode(type),
        requestTypes: requestTypes(type),

        ticketHeaderParser: (items) => ticketParser(type, items),
        prepareCSVItems: (items) => prepareCSVItems(type, items)
    }
}

export default viewHelpers
