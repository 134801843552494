import Login from "@/view/auth/Login"
import Forgot from "@/view/auth/Forgot"
import Vue from "vue"
import router from "@/router"

export const authRoutes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: "Login | User Dashboard",
        },
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: Forgot,
        meta: {
            title: "Forgot Password | User Dashboard",
        },
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: "Register | User Dashboard",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            beforeRouteEnter(to, from, next) {
                Promise.all([
                    Vue.prototype.$stateManagement.dispatch('teams/logout'),
                    Vue.prototype.$stateManagement.dispatch('auth/logout')
                ]).then(() => {
                    router.push({ name: 'login' })
                }).catch(error => {
                    router.push({ name: 'login' })
                })
            }
        }
    }
]
