import Vue from "vue"

const path = "request-history"

export default {
    history(filterArray, isAdmin = false) {
        const options = { page: 1, items_per_page: 300, order_by: [], }
        const filter = filterArray ?? []
        const all = isAdmin

        return Vue.prototype.$http.post(`${path}/list`, { options, filter, all })
    },
    historyLight(filterArray, isAdmin = false) {
        const options = { page: 1, items_per_page: 300, order_by: [], }
        const filter = filterArray ?? []
        const all = isAdmin

        return Vue.prototype.$http.post(`${path}/list/light`, { options, filter, all })
    },
    // Get specific detail for history
    historyDetail(id, data = null) {
        return Vue.prototype.$http.get(`${path}/${id}`, { data })
    }
}
