<template>
<DemoBase size="xs4">
  <Button
    outlined block
    title="Open BottomSheet"
    @onClick="onButtonClick" />
  <BottomSheet
    @onClose="onBottomSheetClose"
    v-model="bottomSheet">
    Hello world
  </BottomSheet>
</DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase"
import Button from "@/base/components/form/Button"
import BottomSheet from "@/base/components/container/BottomSheet"

export default {
  name: "BottomSheetDemo",
  components: {BottomSheet, Button, DemoBase},
  data: () => ({
    bottomSheet: false,
  }),
  methods: {
    onButtonClick() {
      this.bottomSheet = true
    },
    onBottomSheetClose() {
      this.bottomSheet = false
    },
  },
}
</script>

<style scoped>

</style>