<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-progress-linear indeterminate v-if="isLoading" />
    <router-view v-else />
  </v-app>
</template>

<script>
import Vue from "vue"
import appMixin from "@/base/data/mixins/appMixin"
import {mapGetters} from "vuex"
import qrMenuItems from "@/data/menu"
import createAxios from "@/base/network/api"
import TeamService from "@/services/TeamService"
import router from "@/router";

export default {
  name: 'App',
  mixins: [appMixin],
  computed: {
    ...mapGetters({
      authenticated: 'auth/isLoggedIn',
      myPages: 'roles/myPages',
      fullName: 'auth/fullName',
      email: 'auth/email',
      editorId: 'auth/editorId',
    }),
  },
  data: () => ({
    isLoading: true,
  }),
  sockets: {
    connect: function () {
      console.log("Socket connected")
    },
    disconnect: function () {
      console.log("Socket disconnected")
    },
  },
  methods: {
    savePages() {
      if (this.authenticated && this.$store.getters['roles/page'].items.length !== 0) {
        this.isLoading = false
        return
      }

      // Save all pages
      let allPages = qrMenuItems.map(item => {
        if (item.isRoot && item.child === undefined) return item.name
        else if (item.child) return item.child.map(c => c.name)
      }).flat()

      // Filter allowed page items by session
      let pageItems = qrMenuItems.map(copyItem => {
        let item = { ...copyItem }
        if (item.child) {
          item.child = item.child.filter(subElement => {
            return this.myPages.includes(subElement.name)
          })
          if (item.child.length === 0) return null
        }

        return item
      }).filter(item => item != null)

      // Persist allowed pages
      let allowedPages = qrMenuItems.map(item => {
        if (item.isRoot && item.child === undefined) return item.name
        else if (item.child) return item.child.map(child => child.name)
      }).flat()

      // console.log("All Pages", allPages)
      // console.log("Allowed", allowedPages)
      // console.log("Page Items", pageItems)

      Vue.prototype.$stateManagement.dispatch('roles/setPages', { allPages, allowedPages, pageItems }).then(() => {
        this.redirection()
      })
    },
    fetchTeams() {
      if (this.authenticated && this.$store.getters['teams/allTeams'].length === 0) {
        console.log("Fetching teams")
        TeamService.getTeams().then(response => {
          console.log("Teams", response.data.data)
          Vue.prototype.$stateManagement.dispatch('teams/setAllTeams', response.data.data)
        })
      }
    },
    subscribeSocketChannel() {
      console.log("[APP] -> Subscribe")
      this.sockets.subscribe(this.socketChannel(), (data) => {
        console.log(`[APP] -> Subscribed qr-request-${this.editorId}`)
        console.log(`[APP]`, data)
      })
    },
    unsubscribeSocketChannel() {
      console.log("[APP] -> Unsubscribe")
      this.sockets.unsubscribe(this.socketChannel())
    },
    redirection() {
      if (this.$route.query?.from) {
        if (this.$route.params.to) {
          router.push(this.$route.params?.to?.path)
        } else {
          router.push({ name: 'Welcome' })
        }
      } else {
        router.push({ name: 'Welcome' })
      }
    }
  },
  watch: {
    authenticated: {
      immediate: true,
      handler(to) {
        if (to) {
          Vue.prototype.$http = createAxios()
          this.savePages()
          this.fetchTeams()
          this.$socket.connect()
          this.subscribeSocketChannel()
        } else {
          this.isLoading = false
          this.unsubscribeSocketChannel()
          this.$socket.disconnect()
        }
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (!!window.ATL_JQ_PAGE_PROPS && !!window.ATL_JQ_PAGE_PROPS.fieldValues) {
          window.ATL_JQ_PAGE_PROPS.fieldValues.description = `... is not working correctly. \n\n\nName: ${this.fullName}\nEmail: ${this.email}\nPage: ${to.meta.title ?? ""}\nURL: ${window.location.href}.\nUser Agent: ${navigator.userAgent}.\nScreen Resolution: ${screen.width}x${screen.height}`
        }
      },
    },
  },
}
</script>
