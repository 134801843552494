const requestIdleCallback = window.requestIdleCallback || (callback => {
    let start = Date.now()
    return setTimeout(() => {
        let data = {
            didTimeout: false,
            timeRemaining() {
                return Math.max(0, 50 - (Date.now() - start))
            }
        }
        callback(data)
    }, 1)
})

export default requestIdleCallback