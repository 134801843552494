import Vue from "vue"

const path = "account"

export default {
    reset(data) {
        return Vue.prototype.$http.post(`${path}/reset-password`, data)
    },
    history(filterObject = [], isAdmin = false) {
        const options = { page: 1, items_per_page: 50, order_by: [], }
        const filter = filterObject ?? []
        const all = isAdmin
        return Vue.prototype.$http.post(`${path}/reset-password/list`, { options, filter, all })
    }
}
