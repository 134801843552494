import Vue from "vue"
import types from "@/data/types"

const pageProjectMixin = {
    methods: {
        getAppTeamList() {
            switch (this.$route.meta.type) {
                case types.OSS_APP_INVENTORY:
                case types.OSS_APP:
                    return this.$store.getters['teams/appTeams'].join(", ")
            }
            return ""
        },
        socketChannel: () => {
            const channel = 'qr-request'
            const editorId = Vue.prototype.$stateManagement.state.auth?.account?.editor_id
            return [channel, editorId].join("-")
        }
    }
}

export default pageProjectMixin