import types from "@/data/types"

const refreshTypes = [
    "GENERATE_QR_ASYNC", "INVENTORY_API_ASYNC", "INVENTORY_APP_ASYNC",
    "INVENTORY_WEB_ASYNC", "REFRESH_CREDITS", "REFRESH_INVOICE",
]

const getQueryParams = (type, item) => {
    switch (type) {
        case "RESET_ACCOUNT_PASSWORD":
            let request_data = item._item.request_data
            return {
                account: [request_data.account_team, request_data.account_user].join(":")
            }
        case "CREATE_BALLPARK_ACCOUNT":
            let ballpark_data = item._item.request_data
            return {
                account: [ballpark_data.account_user, ballpark_data.account_password, ballpark_data.account_name, ballpark_data.account_surname].join(":")
            }
        case "REFRESH_INVOICE":
            return {
                accounts: item._item.request_data.accounts.map(acc => [acc.account_team, acc.account_user, acc.account_password].join(":")).join("\n"),
                enableFilter: item._item.request_data.filter != "",
                filter: item._item.request_data.filter,
            }
        case "WEB_PRINT_TICKET":
            return {
                history: item._item.request_data.request_id
            }
        case "SIDE_DMS":
            return {
                url: item._item.request_data.links.join(";")
            }
    }

    if (refreshTypes.includes(type)) {
        console.log("Refresh types, return query", type)

        let isSeatgeek = item._item.platform === 'seatgeek'
        let accounts = isSeatgeek ?
            item._item.request_data.accounts?.map(account => account.account_performer + ':' + account.account_token).join('\n') :
            item._item.request_data.accounts.map(acc => [acc.account_team, acc.account_user, acc.account_password].join(":")).join("\n")

        return {
            accounts: accounts,
            from_time: item._item.request_data.from_time,
            to_time: item._item.request_data.to_time,
            has_tbd: item._item.request_data.tbd_included || false,
        }
    }

    console.log("No query params for this type", type)
    return {}
}

export default getQueryParams
