import baseProps from "@/base/data/props/base";

const buttonProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null,
    },
    buttonIcon: {
        type: String,
        required: false,
        default: null,
    },
    prependIcon: {
        type: String,
        required: false,
        default: null,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
}

export default buttonProps