import { io } from 'socket.io-client'

class SocketioService {
    socket;
    constructor() {}

    setupSocketConnection() {
        this.socket = io(process.env.VUE_APP_SOCKET_URL);
        return this.socket
    }
}

export default new SocketioService();