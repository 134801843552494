<template>
  <div v-bind:class="computedDivClass">
    <BottomSheet
      v-model="show"
      :class-object="computedClass"
      class="mb-10"
      @onClose="onBottomSheetClose">
      <Alert type="error" :message="errorMessage" />
      <DataTable
        :title="title"
        :loading="localLoading"
        :custom-options="datatableOptions"
        :custom-actions="customActions"
        :custom-loading-type="customLoadingType"
        :custom-header-values="headerValues"
        :default-slots="false"
        :datatable-items="items">
        <template v-slot:item.editor="{ item }">
          <TooltipLabel v-if="item.editor.dashboard_user" top :label="item.editor.dashboard_user">
            {{ [item.editor.first_name, item.editor.last_name].join(" ") }}
            <v-chip v-if="item.editor.is_admin" class="px-1 ma-0" label x-small>
              admin
            </v-chip>
          </TooltipLabel>
          <span v-else>API Test</span>
        </template>
        <template v-slot:item.data_list="{ item }">
          {{ item.data_list?.length }} tickets generated
        </template>
        <template v-slot:item.status="{ item }">
          <TooltipLabel top :label="item.request_id">
            <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
            <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
            <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
            <span v-else>{{ item.status }}</span>
          </TooltipLabel>
        </template>

        <template v-slot:item.created_at="{ item }">
          <TooltipLabel top :label="printableDate(item.created_at)">
            {{ printableDate(item.created_at) }}
          </TooltipLabel>
        </template>
      </DataTable>
    </BottomSheet>
  </div>
</template>

<script>
import PageMixin from "@/base/data/mixins/pageMixin"
import ComponentMixin from "@/base/data/mixins/componentMixin"
import BottomSheet from "@/base/components/container/BottomSheet"
import DataTable from "@/base/components/container/DataTable"
import bottomHistoryProps from "@/base/data/props/bottomHistory"
import LoadingCard from "@/base/components/view/LoadingCard"
import Alert from "@/base/components/view/Alert"
import TooltipLabel from "@/base/components/container/TooltipLabel"

export default {
  name: "BottomHistory",
  components: {LoadingCard, DataTable, BottomSheet, Alert, TooltipLabel},
  mixins: [PageMixin, ComponentMixin],
  emits: ['onClose', 'onAction'],
  props: bottomHistoryProps,
  data: () => ({
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
    localLoading: true,
  }),
  computed: {
    customActions() {
      return [
        {
          title: "Download",
          click: this.onItemClick,
          isRendered: (item) => {
            return item.status === "completed"
          }
        }
      ]
    },
    customLoadingType() {
      return "table-thead, table-tbody, table-tfoot"
    }
  },
  methods: {
    onBottomSheetClose() {
      this.$emit('onClose')
    },
    onItemClick(item) {
      this.$emit('onAction', item)
    },
  },
  watch: {
    errorMessage: function(newValue) {
      // this.localLoading = newValue == null
    },
    '$attrs.loading': function(newValue) {
      this.localLoading = newValue
    },
    items: function(newValue) {
      this.localLoading = newValue === []
    },
  }
}
</script>
