<template>
<CenterContent size="xs3">
  <Button class="my-3" title="Return to Homepage" @onClick="$router.push('/')" outlined block color="primary"></Button>
  <Button class="mb-1" title="DataTable Demo" @onClick="$router.push({ name: 'datatable-demo' })" outlined block></Button>
  <Button class="mb-1" title="Modal Demo" @onClick="$router.push({ name: 'modal-demo' })" outlined block></Button>
  <Button class="mb-1" title="Loading Modal Demo" @onClick="$router.push({ name: 'loading-modal-demo' })" outlined block></Button>
  <Button class="mb-1" title="Confirm Modal Demo" @onClick="$router.push({ name: 'confirm-modal-demo' })" outlined block></Button>
  <Button class="mb-1" title="Date Picker" @onClick="$router.push({ name: 'datepicker-demo' })" outlined block></Button>
  <Button class="mb-1" title="Date Range Picker" @onClick="$router.push({ name: 'date-range-picker-demo' })" outlined block></Button>
  <Button class="mb-1" title="Checkbox" @onClick="$router.push({ name: 'checkbox-demo' })" outlined block></Button>
  <Button class="mb-1" title="Copyable Button" @onClick="$router.push({ name: 'copyable-demo' })" outlined block></Button>
  <Button class="mb-1" title="Radio Buttons" @onClick="$router.push({ name: 'radiobutton-demo' })" outlined block></Button>
  <Button class="mb-1" title="SelectBox" @onClick="$router.push({ name: 'selectbox-demo' })" outlined block></Button>
  <Button class="mb-1" title="Action Container Demo" @onClick="$router.push({ name: 'action-container-demo' })" outlined block></Button>
  <Button class="mb-1" title="BottomSheet Demo" @onClick="$router.push({ name: 'bottom-sheet-demo' })" outlined block></Button>
  <Button class="mb-1" title="FormHeader Demo" @onClick="$router.push({ name: 'form-header-demo' })" outlined block></Button>
  <Button class="mb-1" title="Panel Demo" @onClick="$router.push({ name: 'panel-demo' })" outlined block></Button>
</CenterContent>
</template>

<script>
import CenterContent from "@/base/components/container/CenterContent"
import Button from "@/base/components/form/Button"

export default {
  name: "Demo.vue",
  components: {Button, CenterContent},
}
</script>

<style scoped>

</style>