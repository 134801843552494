<template>
<DemoBase size="xs4">
  <strong>Hidden false demosu</strong>
  <Copyable :hidden="false">
    Helloasdasdasd
  </Copyable>

  <strong class="mt-2">Sadece buton demosu (hello world)</strong>
  <Copyable>
    hello world
  </Copyable>


  <strong class="mt-2">No snackbar (testing)</strong>
  <Copyable no-snackbar>
    testing
  </Copyable>
</DemoBase>
</template>

<script>
import DemoBase from "@/base/demo/base/DemoBase"
import Copyable from "@/base/components/container/Copyable"

export default {
  name: "CopyableDemo",
  components: {Copyable, DemoBase}
}
</script>

<style scoped>

</style>