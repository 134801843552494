import types from "@/data/types"

const filters = (type) => {
    switch (type) {
        case types.OSS_API:
            return [{"column": "api_transaction_type", operator: "=", "value": "oss_api_refresh_ticket"}]
        case types.OSS_APP:
            return [{"column": "api_transaction_type", operator: "=", "value": "oss_app_refresh_ticket"}]
        case types.OSS_WEB:
            return [{"column": "api_transaction_type", operator: "=", "value": "oss_web_refresh_ticket"}]
        case types.OSS_API_INVENTORY:
            return [{"column": "request_type", operator: "=", "value": "INVENTORY_API_ASYNC"}]
        case types.OSS_APP_INVENTORY:
            return [{"column": "request_type", operator: "=", "value": "INVENTORY_APP_ASYNC"}]
        case types.OSS_WEB_INVENTORY:
            return [{"column": "request_type", operator: "=", "value": "INVENTORY_WEB_ASYNC"}]
        case types.XLS_TO_QR:
            return [
                {"column": "request_type", operator: "=", "value": "GENERATE_QR"},
                {"column": "platform", operator: "=", "value": "ticketmaster"},
            ]
        case types.TM_PHONE:
            return [{"column": "api_transaction_type", operator: "=", "value": "ticketmaster_side_dms_refresh_ticket"}]
        case types.TM_INVOICES:
            return [{"column": "request_type", operator: "=", "value": "REFRESH_INVOICE"}]
        case types.PK_PASS:
            return [{"column": "request_type", operator: "=", "value": "PK_PASS"}]
        case types.BALLPARK_VERIFY:
            return [{"column": "request_type", operator: "=", "value": "BALLPARK_VERIFY_EMAIL"}]
        case types.BALLPARK_CREATE:
            return [{"column": "request_type", operator: "=", "value": "CREATE_BALLPARK_ACCOUNT"}]
        case types.OSS_RESET:
            return [{"column": "request_type", operator: "=", "value": "RESET_ACCOUNT_PASSWORD"}]
        case types.BALLPARK_APP:
            return [{"column": "api_transaction_type", operator: "=", "value": "mlb_app_refresh_ticket"}]
        case types.BALLPARK_WEB:
            return [{"column": "api_transaction_type", operator: "=", "value": "mlb_web_refresh_ticket"}]
        case types.SG:
            return [{"column": "api_transaction_type", operator: "=", "value": "com_api_refresh_ticket"}]
        case types.OSS_CREDITS:
            return [{"column": "request_type", operator: "=", "value": "REFRESH_CREDITS"}]
        case types.XLS_TO_QR_SG:
            return [
                {"column": "request_type", operator: "=", "value": "GENERATE_QR"},
                {"column": "platform", operator: "=", "value": "seatgeek"},
            ]
        default:
            return ""
    }
}

export default filters
