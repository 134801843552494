<template>
<div v-bind:class="computedDivClass">
  <v-checkbox
    v-bind:class="computedClass"
    :label="title"
    :color="color"
    v-model="localValue"
    v-bind="$attrs">
    <template v-if="custom" v-slot:label>
      <slot></slot>
    </template>
  </v-checkbox>
</div>
</template>

<script>
import ComponentMixin from "@/base/data/mixins/componentMixin"
import checkboxProps from "@/base/data/props/checkbox"

export default {
  name: "Checkbox",
  mixins: [ComponentMixin],
  props: checkboxProps,
  data: () => ({
    localValue: null,
  }),
  created() {
    this.localValue = this.$attrs.value ?? ""
    this.$watch('localValue', (value) => {
      this.$emit('input', !!value)
    })
  },
  watch: {
    '$attrs.value': function(newValue) {
      this.localValue = newValue
    }
  }
}
</script>

<style scoped>

</style>