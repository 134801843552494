import {
    headerAlignmentValues,
    headerIgnoreValues,
    headerMatches,
    headerWidthMatches
} from "@/base/data/matches"

// Returns slugify version of given string
export const slugify = str =>
    str.toLowerCase().trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')

// Returns snake case version of given string
export const toSnakeCase = str =>
    str &&
    str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');

// Returns word version of given camel case string
export const camelToTitle = (camelCase) =>
    camelCase.replace(/([-_][a-z])/g, group =>
        group.toUpperCase()
            .replace('-', '')
            .replace(/([A-Z])/g, (match) => ` ${match}`))
        .replace(/^./, (match) => match.toUpperCase())
        .replace(/_/, '')
        .replace(/_/, '')
        .replace(/_/, '')
        .trim()

// Returns title version of given slug
export const makeTitle = title => {
    return title.split("-").map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()).join(' ')
}

// Filter given array with given query
export const filterItems = (items, query) => {
    if (query == null) {
        return items
    }
    return items.filter(item => {
        if (typeof item === 'string') {
            return item.toLowerCase().includes(query.toLowerCase())
        } else if (typeof item === 'object') {
            return Object.values(item).join('').toLowerCase().includes(query.toLowerCase())
        } else if (typeof item === 'array') {
            return item.join('').toLowerCase().includes(query.toLowerCase())
        }

        return false
    })
}

// Get header values of given data structure
export const getHeaderValues = (object, ignore, customHeader) => {
    if (process.env.NODE_ENV === 'development') {
        console.log("DataTable Item: ", object)
        console.log("DataTable Custom Header: ", customHeader)
        console.info("-----")
    }

    let keys = []
    let allIgnore = ignore.concat(headerIgnoreValues)
    if (customHeader != null && customHeader.length !== 0) {
        keys = customHeader.filter(item => allIgnore.includes(item) === false)
    } else {
        keys = Object.keys(object).filter(item => allIgnore.includes(item) === false)
    }

    return keys.map(key => {
        let alignment = headerAlignmentValues[key] || ''
        let text = headerMatches[key] != null ? headerMatches[key] : camelToTitle(key.replace(/^_/, ''))

        // Default values with using #
        return {
            text: text,
            value: key,
            width: headerWidthMatches[key],
            align: alignment,
        }
    })
}

export const copyToClipboard = (id, snackbar) => {
    let value = document.querySelector("#"+id).innerHTML
    if (value == null) return

    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value)
        snackbar()
    } else {
        let newElement = document.createElement("input")
        newElement.value = value
        newElement.style.position = "fixed"
        newElement.style.left = "-999999px"
        newElement.style.top = "-999999px"
        document.body.appendChild(newElement)
        newElement.focus()
        newElement.select()

        try {
            document.execCommand("copy")
            document.body.removeChild(newElement)
            snackbar()
        } catch (err) {
            console.log(err)
        }
    }
}

export const isHTML = (str) => {
    const a = document.createElement('div')
    a.innerHTML = str

    for (var c = a.childNodes, i = c.length; i--; ) {
        if (c[i].nodeType === 1) return true
    }

    return false
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : 2
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getUniqueID = () => "id" + Math.random().toString(16).slice(2)

export const fieldSorter = (fields) => (a, b) => {
    return fields.map(o => {
        let dir = 1
        if (o[0] === '-') {
            dir = -1;
            o = o.substring(1)
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0
    }).reduce((p, n) => p ? p : n, 0);
}

export const camelizeKeys = (arr) => {
    return arr.map(obj => {
        const o = {}
        Object.keys(obj).forEach(k => {
            o[toSnakeCase(k)] = obj[k]
        })

        return o
    })
}

export default {
    slugify, camelToTitle, makeTitle, filterItems, formatBytes,
    getHeaderValues, copyToClipboard, isHTML, getUniqueID,
    toSnakeCase, fieldSorter, camelizeKeys
}
