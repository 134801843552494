import axios from "axios"
import Vue from "vue"
import router from "@/router"

const createAxios = () => {
    function getBaseURL() {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION
    }

    function networkErrorResponse(error) {
        return {
            loading: false,
            timestamp: new Date().getTime(),
            code: error?.code || 400,
            variant: 'error',
            message: error?.message || error.toString(),
            content: error,
        }
    }

    function networkResponse(response, onlyError, auth = false, customMessage = null) {
        // To handle if two alerts in the screen
        let variant = 'info'

        if (auth || response.data?.code !== 100) {
            variant = response.data?.code === 100 ? 'success' : 'error'
        } else {
            let status = response.data.data.request_status || (response.data?.code === 100 ? 'completed' : 'error')
            variant = (status === 'completed' || status === 'requested') ? 'success' : 'error'
        }

        let messageString = () => {
            if (onlyError && variant === 'success') {
                return null
            }

            return response.data?.data?.api_response?.response || response.data?.message || response.message
        }

        return {
            loading: false,
            timestamp: new Date().getTime(),
            code: response.data?.code || 400,
            variant: variant,
            message: customMessage ?? messageString(),
            content: response,
        }
    }

    function networkResponseFromError(error) {
        if (error.data.data == null) {
            return networkErrorResponse(error.data)
        }

        return networkErrorResponse("An error occurred, please try again!")
    }

    const axiosInstance = axios.create({
        baseURL: getBaseURL(),
        timeout: 60000000,
        withCredentials: false,
        headers: {
            Authorization: `${Vue.prototype.$stateManagement.getters["auth/token"]}`,
            'Access-Control-Allow-Origin' : '*',
        }
    })

    axiosInstance.interceptors.response.use(response => {
        response.networkResponse = (onlyError = false, auth = false, customMessage = null) => networkResponse(response, onlyError, auth, customMessage)

        if (response.data.code !== 100 && response.status !== 200) {
            return Promise.reject({ response })
        }

        return response
    }, error => {
        error.networkError = (error, auth = false) => {
            if (error.code === "ERR_NETWORK") { return networkErrorResponse(error) }
            return networkResponseFromError(error.response)
        }

        if (error.toString().indexOf('401') > -1) {
            Promise.all([
                Vue.prototype.$stateManagement.dispatch('teams/logout'),
                Vue.prototype.$stateManagement.dispatch('auth/logout')
            ]).then(() => {
                router.push({ name: 'login' })
            }).catch(error => {
                router.push({ name: 'login' })
            })
            return
        }

        let errorMessage = ["Authentication token not found.", "Authentication failed."]
        if (errorMessage.includes(error.response?.data?.message)) {
            Promise.all([
                Vue.prototype.$stateManagement.dispatch('teams/logout'),
                Vue.prototype.$stateManagement.dispatch('auth/logout')
            ]).then(() => {
                router.push({ name: 'login' })
            }).catch(error => {
                router.push({ name: 'login' })
            })
            return
        }

        return Promise.reject(error)
    })

    return axiosInstance
}

export default createAxios
