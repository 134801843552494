import {makeTitle, slugify} from "@/base/helper/utils"
import qrMenuItems from "@/data/menu"

export const findTitleById = function (id) {
    if (id === null ||  id === undefined) return ""

    let selected = null
    qrMenuItems?.forEach(menu => {
        if (menu.name === id) {
            selected = menu
        } else if (menu.child !== null && menu.child !== undefined) {
            menu.child.forEach(item => {
                if (item.id === id) {
                    selected = item
                }
            })
        }
    })

    return (selected == null) ? "" : selected.title
}

export const findIdByTitle = function(items, title) {
    if (title === null ||  title === undefined) return null

    let selected = null
    items.forEach(item => {
        if (slugify(item.title) === title) {
            selected = item
        }
    })

    return selected
}

export const silentPush = function (route, param) {
    if (!!param === false) {
        console.info("Null or undefined param is passed to silentPush()")
        param = ''
    }

    route.params[Object.keys(route.params)[0]] = param
    document.title = getTitleByRoute(route)

    history.pushState(
        {},
        null,
        "/" + route.name + "/" + param
    )
}

export const getTitleByRoute = function(to) {
    const defaultTitle = "QR Application"
    const defaultParameter = "%@"

    if (!!to.meta.title) {
        const title = to.meta.title
        if (title.includes(defaultParameter) && Object.values(to.params).length > 0 && !!Object.values(to.params)[0]) {
            const parameterTitle = makeTitle(Object.values(to.params)[0])
            return title.replace(defaultParameter, parameterTitle)
        } else {
            return title.replace(defaultParameter, "")
        }
    }

    return defaultTitle
}