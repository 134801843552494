<template>
  <div v-if="message" v-bind="computedDivClass">
    <v-alert v-bind="$attrs" v-bind:class="computedClass">
      <v-row align="center">
        <v-col cols="9">
          <SP v-html="message" />
        </v-col>
        <v-col cols="3">
          <slot content="action"></slot>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loading && value == null" indeterminate color="white" />
      <v-progress-linear v-else-if="value != null" :buffer-value="value" model-value="10" color="white" />
    </v-alert>
  </div>
</template>

<script>
import alertProps from "@/base/data/props/alert"
import componentMixin from "@/base/data/mixins/componentMixin"
import SP from "@/base/components/container/SP"

export default {
  name: "Alert",
  props: alertProps,
  components: {SP},
  mixins: [componentMixin],
}
</script>
