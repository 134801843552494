<template>
  <div :class="computedDivClass">
    <v-text-field
      :label="label"
      :rules="rule"
      :loading="loading"
      :hint="computedHintMessage"
      :class="computedClass"
      :type="calculatedType"
      :append-icon="appendingIcon"
      :prepend-inner-icon="computedIcon"
      :autocomplete="autocomplete"
      @click:append="passwordShowHide = !passwordShowHide"
      v-bind="$attrs"
      v-model="localValue">
    </v-text-field>
  </div>
</template>

<script>
import inputProps from "@/base/data/props/input"
import InputType from "@/base/data/types/inputType"
import { requiredRule, emailRule, numberRule, passwordRule, passwordRuleMinMax } from "@/base/data/validation/regex"
import componentMixin from "@/base/data/mixins/componentMixin"

export default {
  name: "Input",
  props: inputProps,
  mixins: [componentMixin],
  computed: {
    init() {
      if (this.type === InputType.Password && this.max === null) {
        throw new Error('Missing props for Password Type')
      }
    },
    calculatedType() {
      switch (this.type) {
        case InputType.Alphanumeric:
          return "text"
        case InputType.Email:
          return "text"
        case InputType.Password:
          return this.passwordShowHide ? "text" : "password"
        case InputType.Number:
          return "number"
      }
    },
    rule() {
      switch (this.type) {
        case InputType.Alphanumeric:
          return requiredRule(this.label)
        case InputType.Email:
          return emailRule
        case InputType.Password:
          if (this.min != null) {
            return passwordRuleMinMax(this.min, this.max)
          } else {
            return passwordRule(this.max)
          }
        case InputType.Number:
          return numberRule
        case InputType.Other:
          return []
        case InputType.Text:
          if (this.min && this.max) {
            return passwordRuleMinMax(this.min, this.max)
          } else if (this.max) {
            return passwordRule(this.max)
          }
      }
    },
    appendingIcon() {
      if (this.showEyeIcon === false) {
        return ''
      } else if (this.passwordShowHide)  {
        return 'mdi-eye'
      } else {
        return 'mdi-eye-off'
      }
    },
    showEyeIcon() {
      return (this.type === InputType.Password && !this.hidePasswordIcon)
    },
    computedHintMessage() {
      if (this.hint != null) {
        return this.hint
      } else if (this.min != null) {
        return `At least ${this.min} characters`
      }
    },
    computedDivClass() {
      let densityClasses = this.dense ? ['my-1'] : ['my-2']
      return [].concat(densityClasses, this.classDivObject)
    },
    computedIcon() {
      if (this.noIcon) {
        return ""
      } else if (this.type === InputType.Email) {
        return "mdi-at"
      } else if (this.type === InputType.Password) {
        return "mdi-form-textbox-password"
      }

      return this.icon
    },
    autocomplete() {
      return this.disableAutocomplete ? "new-password" : ""
    },
  },
  data: () => ({
    passwordShowHide: false,
    localValue: null,
  }),
  mounted() {
    let initalization = this.init
  },
  created() {
    this.localValue = this.$attrs.value ?? ""
    this.$watch('localValue', (value) => {
      this.$emit('input', value)
    })
  },
  watch: {
    '$attrs.value': function(newValue) {
      this.localValue = newValue
    }
  }
}
</script>

<style scoped>

</style>
