<template>
  <DemoBase size="xs4">
    <ActionContainers
      fixed bottom left>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab dark small
            color="grey"
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <span>Test Accounts</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab dark small
            color="grey"
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Get Latest Scrape</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab dark small
            color="grey"
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
        </template>
        <span>Show My History</span>
      </v-tooltip>
    </ActionContainers>
  </DemoBase>
</template>

<script>
import ActionContainers from "@/base/components/container/ActionContainers"
import DemoBase from "@/base/demo/base/DemoBase"

export default {
  name: "ActionContainersDemo",
  components: {DemoBase, ActionContainers}
}
</script>

<style scoped>

</style>