import Vue from "vue"

const path = "editor"

export default {
    login(username, password) {
        return Vue.prototype.$http.post(`${path}/login`, { username, password })
    },
    forget(username) {
        return Vue.prototype.$http.post(`${path}/forgot-password`, { username })
    },
    reset(code, password) {
        return Vue.prototype.$http.post(`${path}/reset-password`, { code, password })
    },
    create(data) {
        return Vue.prototype.$http.post(`${path}`, { data })
    },
    update(data) {
        return Vue.prototype.$http.put(`${path}/info`, { data })
    },
    list() {
        const options = { page: 1, items_per_page: 50, order_by: [], }
        const filter = []
        return Vue.prototype.$http.post(`${path}/list`, { options, filter })
    },
}
